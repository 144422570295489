import "./list.scss"
import Sidebar from 'react-sidebar';
import Navbar from "../../components/navbar/Navbar"
import Chatbot from "../../components/chat-bot/chat-bot"
import { useNavigate } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const ChatBrandBot = () => {

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleSearch = () => {
    navigate("/search");
  };

  const handleChatbot = () => {
    navigate("/chatbot");
  };

  const handleHome = () => {
    navigate("/start");
    window.scrollTo(0, 0)
  };

  const handleAboutUs = () => {
    navigate("/about");
    window.scrollTo(0, 0)
  };

  const handleTerms = () => {
    navigate("/terms");
    window.scrollTo(0, 0)
  };

  const handleHelp = () => {
    navigate("/help");
    window.scrollTo(0, 0)
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const sidebarContent = (
    <div className="sidebar">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>  
        <button type="submit" className="home-text" onClick={handleChatbot}>Chat bot</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        <button type="submit" className="home-text" onClick={handleAboutUs}>About Us</button>
        <button type="submit" className="home-text" onClick={handleHelp}>Help</button>
    </div>
  );

  return (
    <div className="list">
      {/* <Sidebar className="sidebar"/> */}
      <div className="listContainer">
      <div className="header-container">
      <div className="logo-img-container" onClick={handleHome}>
        <img src="/images/logo.png" alt="logo" className="logo"/>
        <div className="logo-name">BrandForgeX</div>
      </div>
      <div className="search">
          <input
            type="text"
            placeholder="Search a Business"
            className="search-box"
            onClick={handleSearch}
          />
          <div className="search-icon-container"  onClick={handleSearch}>
          <SearchOutlinedIcon className="search-icon"/>
          </div>
            <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="white"/>
            </svg>
            </button>
        </div>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
      <div className="login">
        <div className="button-container">
        <button type="submit" className="home-text1" onClick={handleHome}>Home</button>  
        <button type="submit" className="home-text2" onClick={handleChatbot}>Chatbot</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        </div>
      </div>
    </div>
          <div
        style={{
          borderBottom: "0.5px solid rgba(255, 255, 255, 0.20)",
          width: "90%",
          margin: "auto",
        }}
      />
        {/* <Navbar/> */}
        <Chatbot/>
      </div>
    </div>
  )
}

export default ChatBrandBot