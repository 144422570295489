import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "brandforge-x.firebaseapp.com",
  projectId: "brandforge-x",
  storageBucket: "brandforge-x.appspot.com",
  messagingSenderId: "955024201944",
  appId: "1:955024201944:web:42bf875d859b9874ef1fad",
  measurementId: "G-5GYCXZG9LG"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
