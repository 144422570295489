import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import BChart from "../../components/bar-chart/bar-chart";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getDocs, collection, where, query, orderBy, startAt, endAt, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import emailjs from "emailjs-com";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(
    localStorage.getItem("surveySubmitted") === "true"
  );

  // State to keep track of the selected options for each question
  const [needWebsite, setNeedWebsite] = useState("");
  const [needLogo, setNeedLogo] = useState("");
  const [needSocialMedia, setNeedSocialMedia] = useState("");

  const [needWebsiteForm, setNeedWebsiteForm] = useState("");
  const [needLogoForm, setNeedLogoForm] = useState("");
  const [needSocialMediaForm, setNeedSocialMediaForm] = useState("");

  const { state } = useLocation();
  const bname = state ? state.bname : null;
 
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid;

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (uid) {
        try {
          const userQuery = doc(db, 'users', uid);
          const userSnapshot = await getDoc(userQuery);

          if (userSnapshot.exists()) {
            const user = userSnapshot.data();
            setUserData(user); // Set the user data to state
          } else {
            console.log("User data doesn't exist");
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();

    // Cleanup function (optional)
    return () => {
      // Perform any cleanup (if needed)
    };
  }, [uid]); // Dependency array to re-run effect when 'uid' changes

  useEffect(() => {
    // Show modal only if survey has not been submitted
    if (!surveySubmitted) {
      const modalTimeout = setTimeout(() => {
        setShowModal(true);
      }, 20000);

      return () => clearTimeout(modalTimeout);
    }
  }, [surveySubmitted]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteSurveySubmission = () => {
    // Remove the survey submission from local storage
    localStorage.removeItem("surveySubmitted");
    // Update the state to reflect that the survey has not been submitted
    setSurveySubmitted(false);
    console.log("deleted")
  };

  const handleSurveySubmit = () => {
    // Set surveySubmitted to true when the submit button is pressed
    setSurveySubmitted(true);
    // Save in local storage
    localStorage.setItem("surveySubmitted", "true");
    // Close the modal
    handleCloseModal();
    // You can also perform any additional actions related to survey submission here

     // Construct the message body including survey data and user's email
      const messageBody = `
      Survey Response:
      Need Website: ${needWebsite}
      Need Logo: ${needLogo}
      Need Social Media Management: ${needSocialMedia}
      User's Email Address: ${userData?.email}
      User's Contact Number: ${userData?.contact1}

      Form Data:
      Website: ${needWebsiteForm}
      Logo: ${needLogoForm}
      Social Media Management: ${needSocialMediaForm}
    `;

    // Create the surveyData object
    const surveyData = {
      message: messageBody,
      from_name: userData?.name,
      to_name: "Geetek Global"
    };

    // Replace these values with your EmailJS service, template, and user IDs
    const serviceID = "service_2hfymq9";
    const templateID = "template_oqli0pj";
    const userID = "5XL_DQuh4gT99u_a5";

    // Send email using EmailJS
    emailjs.send(serviceID, templateID, surveyData, userID)
      .then((response) => {
        console.log("Email sent successfully:", response);
        setEmailSent(true);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
      });
  };

  // Function to check if at least one option in each question group is selected
  const isSubmitDisabled = () => {
    return needWebsite === "" || needLogo === "" || needSocialMedia === "";
  };  

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar bname={bname}/>
        <div className="dash-image-container">
          <div className="dash-text-container">
            <div className="dash-text1">Brand Forge X bringing <br/> more updates</div>
            <div className="dash-text2">Stay Tuned!</div>
            <button className="dash-button">Explore Updates</button>
          </div>
          <img src="/images/cube-dash.svg" alt="logo" className="dash-image" />
        </div>
        <div className="widgets">
          <Widget type="views" bname={bname}/>
          <Widget type="visits" bname={bname}/>
          <Widget type="new" bname={bname}/>
          <Widget type="active" bname={bname}/>
          <Widget type="referrals" bname={bname}/>
        </div>
        <div className="charts">
          {/* <Featured /> */}
          <Chart title="Customers Map (Monthly)" aspect={4 / 1} bname={bname}/>
          {/* <BChart title="Customers Map (Monthly)" aspect={4 / 1}/> */}
        </div>
        <div className="state">
          {/* <Featured />
          <div className="listContainer">
            <div className="row">
              <div className="listTitle">Latest Transactions</div>
              <Link to="/orders" style={{ textDecoration: 'none', color: '#43A921' }}>
                <div className="listTitle1">{`View all >`}</div>
              </Link>
            </div>
            <Table />
          </div> */}
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            {/* <span className="close" onClick={handleCloseModal}>
              &times;
            </span> */}
            <div className="modal-head">
              <div className="modal-head-text">
                Please Kindly Fill This Survey
              </div>
            </div>
            <div className="modal-body">
              <input className="input-modal" placeholder="Do you need a website?" disabled={needWebsite === "no"} onChange={(e) => setNeedWebsiteForm(e.target.value)} />
              <div className="radio-group">
                <label>
                  Yes
                  <input type="radio" name="yesNo" value="yes" style={{ marginLeft: '10px', marginRight: '20px' }} onChange={() => setNeedWebsite("yes")} />
                </label>
                <label>
                  No
                  <input type="radio" name="yesNo" value="no" style={{ marginLeft: '10px' }} onChange={() => setNeedWebsite("no")} />
                </label>
              </div>
              <input className="input-modal" placeholder="Do you need a Logo?" disabled={needLogo === "no"} onChange={(e) => setNeedLogoForm(e.target.value)} />
              <div className="radio-group">
                <label>
                  Yes
                  <input type="radio" name="yesNo2" value="yes" style={{ marginLeft: '10px', marginRight: '20px' }} onChange={() => setNeedLogo("yes")} />
                </label>
                <label>
                  No
                  <input type="radio" name="yesNo2" value="no" style={{ marginLeft: '10px' }} onChange={() => setNeedLogo("no")} />
                </label>
              </div>
              <input className="input-modal" placeholder="Do you need Social Media Management?" disabled={needSocialMedia === "no"} onChange={(e) => setNeedSocialMediaForm(e.target.value)} />
              <div className="radio-group">
                <label>
                  Yes
                  <input type="radio" name="yesNo3" value="yes" style={{ marginLeft: '10px', marginRight: '20px' }} onChange={() => setNeedSocialMedia("yes")} />
                </label>
                <label>
                  No
                  <input type="radio" name="yesNo3" value="no" style={{ marginLeft: '10px' }} onChange={() => setNeedSocialMedia("no")} />
                </label>
              </div>
              <div className="button-container">
                <button className="button1" onClick={handleSurveySubmit} disabled={isSubmitDisabled()}>Submit Survey</button>
                <button className="button2" onClick={handleCloseModal}>Ignore</button>
              </div>
              <div className="info-text">This survey helps brand forge X to know if <br />
                you need assistance in your website/logo/social <br />
                management
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;