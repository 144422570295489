import React, { useState } from "react";
import "./stepper.scss";

const Stepper = ({ currentStep }) => {
  const steps = ["Personal Information", "Business Information", "Address Information", "Payment plan"];

  return (
    <div className="stepper-container">
      {steps?.map((step, i) => (
        <div key={i} className="step-item">
          <div className={`step ${i + 1 === currentStep ? "active" : ""} ${i + 1 <= currentStep ? "complete" : ""}`}>
            {i + 1 === currentStep ? (
              <span>&#10003;</span>
            ) : i + 1 <= currentStep - 1 ? (
              <span>&#10003;</span>
            ) : i === currentStep ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 15 15" fill="none">
                <rect width="15" height="15" rx="7.5" fill="#455CBF"/>
              </svg>
            ) : null}
          </div>
          <p className={`text-gray-500 ${i + 1 === currentStep ? "active" : ""}`}>step {i + 1}</p>
          <p className={`text-gray-500 ${i + 1 === currentStep ? "active" : ""}`}>{step}</p>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
