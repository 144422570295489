import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useEffect, useState, useContext } from "react";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";

const Widget = ({ type, bname }) => {
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid;
  const [amount, setAmount] = useState(null);
  const [diff, setDiff] = useState(null);
  let data;

  // Define the dates for the previous month, current month, and today
  const today = new Date();
  const lastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const prevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  let widgetBackgroundColor;

  switch (type) {
    case "views":
      widgetBackgroundColor = "#E3F5FF"; // Set the desired primary blue color here
      break;
    case "visits":
      widgetBackgroundColor = "#E5ECF6"; // Set the desired color for "Orders"
      break;
    case "new":
      widgetBackgroundColor = "#E3F5FF"; // Set the desired color for "Earnings"
      break;
    case "active":
      widgetBackgroundColor = "#E5ECF6"; // Set the desired color for "Products"
      break;
      case "referrals":
      widgetBackgroundColor = "#E3F5FF"; // Set the desired color for "Earnings"
      break;
    default:
      widgetBackgroundColor = "#fff"; // Set a default color if needed
      break;
  }

  switch (type) {
    case "views":
      data = {
        title: "Views",
        isMoney: false,
        query: "users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "new":
      data = {
        title: "New Customers",
        isMoney: false,
        query: "users",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "visits":
      data = {
        title: "Visits",
        isMoney: true,
        query: "users",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "active":
      data = {
        title: "Active Customers",
        query: "users",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
      case "referrals":
      data = {
        title: "Referrals",
        isMoney: true,
        query: "users",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    default:
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (bname) {
          // Query the main data collection for documents with matching bname
          const mainDataQuery = query(collection(db, "users"), where("bname", "==", bname));
          const mainDataSnapshot = await getDocs(mainDataQuery);
  
          // Check if there are matching documents in the main data collection
          if (!mainDataSnapshot.empty) {
            const docData = mainDataSnapshot.docs[0].data();
            let value;
            let counter2Value = 0; // Default counter2Value to 0 for referrals
            if (type === "referrals") {
              // Fetch referral code if type is "referrals"
              value = docData.referralCount || 0;
            } else {
              // Fetch counter1 for other types
              value = docData.counter1 || 0;
              counter2Value = docData.counter2 || 0;
            }
  
            // Set the amount and diff state based on the retrieved values
            setAmount(value);
            setDiff(((value - counter2Value) / value) * 100); // Calculate diff percentage
            return; // Exit the function since data is found in the main collection
          }
  
          // If no document with the provided bname is found in the main data collection,
          // query the additionalData field in all documents
          const allUserDataQuery = query(collection(db, "users"));
          const allUserDataSnapshot = await getDocs(allUserDataQuery);
  
          allUserDataSnapshot.forEach(doc => {
            const userData = doc.data();
            // Check if additionalData exists and has items with matching bname
            if (userData.additionalData) {
              userData.additionalData.forEach(item => {
                if (item.bname === bname) {
                  let value;
                  let counter2Value = 0; // Default counter2Value to 0 for referrals
                  if (type === "referrals") {
                    value = item.referralCount || 0;
                  } else {
                    value = item.counter1 || 0;
                    counter2Value = item.counter2 || 0;
                  }
                  setAmount(value);
                  setDiff(((value - counter2Value) / value) * 100);
                }
              });
            }
          });
  
        } else {
          console.log("No bname provided");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [bname, type]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (uid && !bname) {
          switch (type) {
            case "views":
              const userQuery = doc(db, 'users', uid);
              const userSnapshot = await getDoc(userQuery);
  
              // Assuming the counter is stored in the "counter1" field
              if (userSnapshot.exists()) {
                const counter1Value = userSnapshot.data().counter1 || 0;
                console.log("Counter 1 Value:", counter1Value);
  
                // Check if "counter2" is present
                if (userSnapshot.data().counter2 !== undefined) {
                  const counter2Value = userSnapshot.data().counter2 || 0;
                  console.log("Counter 2 Value:", counter2Value);
  
                  // Calculate the difference percentage
                  const diffPercentage = ((counter1Value - counter2Value) / counter1Value) * 100;
  
                  setDiff(diffPercentage);
                } else {
                  // If "counter2" is not present, set the value to 100
                  setDiff(100);
                }
  
                setAmount(counter1Value);
              } else {
                // If the document doesn't exist, set amount to 0
                setAmount(0);
                setDiff(0); // Set diff to 0 if the document doesn't exist
              }
  
              break;
  
              case "new":
                const userQueryNew = doc(db, 'users', uid);
            const userSnapshotNew = await getDoc(userQueryNew);

            // Assuming the counters are stored in the "counter1" and "counter2" fields for "new"
            if (userSnapshotNew.exists()) {
              const counter1ValueNew = userSnapshotNew.data().counter1 || 0;
              console.log("Counter 1 Value for New:", counter1ValueNew);

              // Check if "counter2" is present
              if (userSnapshotNew.data().counter2 !== undefined) {
                const counter2ValueNew = userSnapshotNew.data().counter2 || 0;
                console.log("Counter 2 Value for New:", counter2ValueNew);

                // Calculate the difference percentage
                const diffPercentageNew = ((counter1ValueNew - counter2ValueNew) / counter1ValueNew) * 100;

                setDiff(diffPercentageNew);
              } else {
                // If "counter2" is not present, set the value to 100
                setDiff(100);
              }

              setAmount(counter1ValueNew);
            } else {
              // If the document doesn't exist, set amount to 0
              setAmount(0);
              setDiff(0); // Set diff to 0 if the document doesn't exist
            }
                
                break;
  
                case "visits":
            const userQueryVisits = doc(db, 'users', uid);
            const userSnapshotVisits = await getDoc(userQueryVisits);

            // Assuming the counters are stored in the "counter1" and "counter2" fields for visits
            if (userSnapshotVisits.exists()) {
              const counter1ValueVisits = userSnapshotVisits.data().counter1 || 0;
              console.log("Counter 1 Value for Visits:", counter1ValueVisits);

              // Check if "counter2" is present
              if (userSnapshotVisits.data().counter2 !== undefined) {
                const counter2ValueVisits = userSnapshotVisits.data().counter2 || 0;
                console.log("Counter 2 Value for Visits:", counter2ValueVisits);

                // Calculate the difference percentage
                const diffPercentageVisits = ((counter1ValueVisits - counter2ValueVisits) / counter1ValueVisits) * 100;

                setDiff(diffPercentageVisits);
              } else {
                // If "counter2" is not present, set the value to 100
                setDiff(100);
              }

              setAmount(counter1ValueVisits);
            } else {
              // If the document doesn't exist, set amount to 0
              setAmount(0);
              setDiff(0); // Set diff to 0 if the document doesn't exist
            }

            break;
  
            case "active":
              const userQueryActive = doc(db, 'users', uid);
              const userSnapshotActive = await getDoc(userQueryActive);
  
              // Assuming the counters are stored in the "counter1" and "counter2" fields for "active"
              if (userSnapshotActive.exists()) {
                const counter1ValueActive = userSnapshotActive.data().counter1 || 0;
                console.log("Counter 1 Value for Active:", counter1ValueActive);
  
                // Check if "counter2" is present
                if (userSnapshotActive.data().counter2 !== undefined) {
                  const counter2ValueActive = userSnapshotActive.data().counter2 || 0;
                  console.log("Counter 2 Value for Active:", counter2ValueActive);
  
                  // Calculate the difference percentage
                  const diffPercentageActive = ((counter1ValueActive - counter2ValueActive) / counter1ValueActive) * 100;
  
                  setDiff(diffPercentageActive);
                } else {
                  // If "counter2" is not present, set the value to 100
                  setDiff(100);
                }
  
                setAmount(counter1ValueActive);
              } else {
                // If the document doesn't exist, set amount to 0
                setAmount(0);
                setDiff(0); // Set diff to 0 if the document doesn't exist
              }
              
              break;

              case "referrals":
              const userQueryReferral = doc(db, 'users', uid);
              const userSnapshotReferral = await getDoc(userQueryReferral);
  
              // Assuming the counters are stored in the "counter1" and "counter2" fields for "active"
              if (userSnapshotReferral.exists()) {
                const counter1ValueReferral = userSnapshotReferral.data().referralCount || 0;
                console.log("Referral Counter 1 Value for Active:", counter1ValueReferral);
  
                // Check if "counter2" is present
                if (userSnapshotReferral.data().counter2 !== undefined) {
                  const counter2ValueReferral = userSnapshotReferral.data().referralCount;
                  console.log("referral Counter 2 Value for Active:", counter2ValueReferral);
  
                  // Calculate the difference percentage
                  const diffPercentageReferral = ((counter1ValueReferral/counter1ValueReferral)) * 100;
  
                  setDiff(diffPercentageReferral);
                } else {
                  // If "counter2" is not present, set the value to 100
                  setDiff(100);
                }
  
                setAmount(counter1ValueReferral);
              } else {
                // If the document doesn't exist, set amount to 0
                setAmount(0);
                setDiff(0); // Set diff to 0 if the document doesn't exist
              }
              
              break;
  
            default:
              break;
          }
        } else {
          console.log("User UID is not available");
          // Handle the case where user UID is not available
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [uid, type, bname]);

  return (
    <div className="widget" style={{ backgroundColor: widgetBackgroundColor }}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {amount ? amount : 0}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="center">
          {/* Conditionally render the percentage based on the type */}
          {type !== "referrals" && (
            <div className={`percentage ${diff < 0 ? "negative" : "positive"}`}>
              {typeof diff === 'number' && !isNaN(diff) ? (
                <>
                  {diff < 0 ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  {diff.toFixed(2)} %
                </>
              ) : (
                <>N/A</>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );  
};

export default Widget;
