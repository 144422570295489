import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { productColumns } from "../../datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  where,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const DatatablePro = () => {
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid;
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('search');

  useEffect(() => {
    // LISTEN (REALTIME)
    if (uid) {
      const unsubscribe = onSnapshot(
        collection(db, "users"),
        (snapshot) => {
          let mergedDataList = [];
          snapshot.forEach((doc) => {
            const userData = doc.data();
            let mergedUserData = {
              id: doc.id,
              ...userData,
            };
            mergedDataList.push(mergedUserData);
            if (userData.additionalData) {
              userData.additionalData.forEach((additionalDataItem, index) => {
                let mergedAdditionalData = {
                  userId: doc.id,
                  ...additionalDataItem,
                  id: `${doc.id}_${index}`,
                };
                mergedDataList.push(mergedAdditionalData);
              });
            }
          });
          setOriginalData(mergedDataList); // Store original data
          setData(mergedDataList); // Set both original and filtered data initially
        },
        (error) => {
          console.error("Error fetching data:", error);
        }
      );
  
      return () => unsubscribe();
    }
  }, [uid]);

  useEffect(() => {
    // Filter data based on search input
    if (searchInput.trim() !== "") {
      const filteredData = originalData.filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
      setData(filteredData);
    } else {
      // Show all data when search input is empty
      setData(originalData);
    }
  }, [searchInput, originalData]);

  const handleDelete = async (id) => {
    try {
      const [userId, index] = id.split('_'); // Splitting the ID to get user ID and index
      if (index !== undefined) {
        // It's an additional item, find and delete from additionalData array
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const additionalData = userData.additionalData || [];
          additionalData.splice(parseInt(index), 1); // Remove the item from additionalData array
          await updateDoc(userRef, { additionalData });
          // Remove the deleted item from data state
          setData(data.filter((item) => item.id !== id));
        }
      } else {
        // It's the main item, delete directly
        await deleteDoc(doc(db, "users", id));
        // Remove the deleted item from data state
        setData(data.filter((item) => item.id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };  

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/products/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Registered Brands
        {/* <Link to="/products/new" className="link">
          Add New
        </Link> */}
      </div>
      <div className="search">
        <input
          type="text"
          placeholder="Search a Brand"
          className="search-box"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <div className="search-icon-container">
          <SearchOutlinedIcon className="search-icon" />
        </div>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={productColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default DatatablePro;