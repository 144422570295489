import "./contact.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SliderC from "../../components/logo-slider/slider";
import {
  collection,
  onSnapshot
} from "firebase/firestore";
import { db } from "../../firebase";
import RotatingSphere from "../../components/sphere/sphere";
import { Canvas } from "react-three-fiber";
import Sidebar from 'react-sidebar';

const Contact = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleSearch = () => {
    navigate("/search");
  };

  const handleHome = () => {
    navigate("/start");
    window.scrollTo(0, 0)
  };

  const handleTerms = () => {
    navigate("/terms");
    window.scrollTo(0, 0)
  };

  const handleAboutUs = () => {
    navigate("/about");
    window.scrollTo(0, 0)
  };

  const handleHelp = () => {
    navigate("/help");
    window.scrollTo(0, 0)
  };

  const handleChatbot = () => {
    navigate("/chatbot");
    window.scrollTo(0, 0)
  };

  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountry(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Filter users based on the user's country and payment status
      const filteredUsers = usersData.filter(user => {
        const mainUserDataMatches = user.country === userCountry && user.payment === 'yes';
        
        // Check if additionalData exists and iterate over it
        if (user.additionalData) {
          const additionalDataMatches = user.additionalData.some(data => data.country === userCountry && data.payment === 'yes');
          return mainUserDataMatches || additionalDataMatches;
        }
  
        return mainUserDataMatches;
      });
  
      console.log('Filtered Users:', filteredUsers); // Log the filtered users
  
      setUsers(filteredUsers);
    });
  
    return () => unsubscribe();
  }, [userCountry]);  

  const fetchCountry = (latitude, longitude) => {
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserCountry(data.countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country:", error);
      });
  };

  const handleRedirect = (user) => {
    window.open(user.link, "_blank");
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const sidebarContent = (
    <div className="sidebar">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        <button type="submit" className="home-text" onClick={handleAboutUs}>About Us</button>
        <button type="submit" className="home-text" onClick={handleHelp}>Help</button>
    </div>
  );

  const handleWorld = () => {
    // Scroll to the element with the id "scroll-target" with smooth behavior
    document.getElementById("scroll-target").scrollIntoView({ behavior: "smooth" });
  };  

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="Aboutcontainer">
      <div className="header-container">
      <div className="logo-img-container" onClick={handleHome}>
        <img src="/images/logo.png" alt="logo" className="logo"/>
        <div className="logo-name">BrandForgeX</div>
      </div>
      <div className="search">
          <input
            type="text"
            placeholder="Search a Business"
            className="search-box"
            onClick={handleSearch}
          />
          <div className="search-icon-container"  onClick={handleSearch}>
          <SearchOutlinedIcon className="search-icon"/>
          </div>
            <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="white"/>
            </svg>
            </button>
        </div>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
      <div className="login">
        <div className="button-container">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        </div>
      </div>
    </div>
    <div className="start-line"/>
    <div className="business">
        <div className="business1">
          <div className="business-text-container">
            <div className="bs-text-1">Help</div>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.20)",
              width: "90%",
              margin: "auto",
              marginBottom: "20px",
            }}
          />
        </div>
        <div className="about-text">
        {/* Welcome to Brandforgex, your premier destination for innovative marketing solutions designed to elevate your brand presence and drive sales. At Brandforgex, we understand the importance of effective marketing in today's competitive business landscape. That's why we've created a dynamic platform that empowers businesses of all sizes to showcase their brand identity and connect with their target audience like never before.

<div className="about-heading">Our Mission</div>

Our mission at Brandforgex is simple: to revolutionize the way businesses approach marketing. We believe that every business, regardless of size or industry, deserves access to cutting-edge marketing tools and strategies that deliver real results. Our goal is to provide a user-friendly platform that simplifies the marketing process and empowers businesses to reach their full potential.

<div className="about-heading">What We Offer</div>

Brandforgex offers a comprehensive suite of marketing services designed to meet the diverse needs of today's businesses:

<br />

<div className="about-sub"><div className="about-sub-heading">- Logo and Brand Showcase:</div> Showcase your brand identity with ease by uploading your logo and branding materials to our platform.</div>
<div className="about-sub1"><div className="about-sub-heading">- Website Link Integration:</div> Drive traffic to your website and increase online visibility by integrating your website link into our platform.</div>
<div className="about-sub1"><div className="about-sub-heading">- Marketing Support:</div> Access expert guidance and support to optimize your marketing efforts and connect with your target audience effectively.</div>

<div className="about-heading">Why Choose Brandforgex?</div>

<div className="about-sub1"><div className="about-sub-heading">- Innovation:</div> We're committed to innovation and continuous improvement. Our platform is constantly evolving to meet the changing needs of our users and the evolving demands of the market.</div>
<div className="about-sub1"><div className="about-sub-heading">- Simplicity:</div> We believe that marketing shouldn't be complicated. That's why we've designed our platform to be intuitive, user-friendly, and accessible to businesses of all skill levels.</div>
<div className="about-sub1"><div className="about-sub-heading">- Results-Driven:</div> At Brandforgex, we're focused on delivering results. Whether you're looking to increase brand awareness, drive sales, or engage with your audience, we're here to help you achieve your goals.</div>

<div className="about-heading">Get Started Today</div>

Ready to take your marketing to the next level? Join the thousands of businesses who trust Brandforgex to help them succeed. Sign up for your account today and discover the power of effective marketing with Brandforgex. */}
{/* 
<div className="about-heading">Contact Us</div> */}

Have questions or need assistance? Our team is here to help. Contact us at <a href="mailto:info@brandforgex.com" style={{ textDecoration: 'underline' }}>info@brandforgex.com</a> to learn more about our services or to speak with a member of our team.

<br></br>
<br></br>

Thank you for choosing Brandforgex. We look forward in helping you unlock your brand's full potential!
        </div>
      </div>
<div className="footer">
<div className="footer1">
<div className="ft-text-container">
<div className="footer-img-container">
        <img src="/images/logo.png" alt="logo" className="footer-logo" onClick={handleHome} style={{ cursor: 'pointer' }}/>
<div className="footer-logo-name" onClick={handleHome} style={{ cursor: 'pointer' }}>BrandForgeX</div>
</div>
<div className="copy-text">Copyright © 2024</div>
{/* <div className="copy-text">SC798823 - Incorporated on 13 February 2024</div> */}
<div className="copy-text">55 Kirkton Avenue, Glasgow, Scotland</div>
<div className="copy-text" onClick={handleTerms}>By Creating an Account, it means you agree to our <span className="underline">Privacy Policy</span> and <span className="underline">Terms of Service</span></div>
</div>
<div
  style={{
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.20)',
    width: '90%',
    margin: 'auto',
  }}
/>
<div className="footer-text-container">
<div className="footer-help"  onClick={handleHome}>Home </div>
<div className="footer-link-container">
<div className="footer-help"  onClick={handleAboutUs}>About Us</div>
<div className="footer-help"  onClick={handleScrollToTop}>Help</div>
</div>
</div>
</div>
</div>
</div>
  );
};

export default Contact;
