import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis
} from "recharts";
import { AuthContext } from "../../context/AuthContext";
import { getDocs, collection, where, query, orderBy, startAt, endAt, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useContext, useEffect, useState } from "react";

const Chart = ({ aspect, title, bname }) => {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([
    { name: "January", Counter1: 0, Counter2: 0 },
    { name: "February", Counter1: 0, Counter2: 0 },
    { name: "March", Counter1: 0, Counter2: 0 },
    { name: "April", Counter1: 0, Counter2: 0 },
    { name: "May", Counter1: 0, Counter2: 0 },
    { name: "June", Counter1: 0, Counter2: 0 },
    { name: "July", Counter1: 0, Counter2: 0 },
    { name: "August", Counter1: 0, Counter2: 0 },
    { name: "September", Counter1: 0, Counter2: 0 },
    { name: "October", Counter1: 0, Counter2: 0 },
    { name: "November", Counter1: 0, Counter2: 0 },
    { name: "December", Counter1: 0, Counter2: 0 },
  ]);

  const uid = currentUser?.uid;

  useEffect(() => {
    const fetchData = async () => {
      if (uid) {
        try {
          const userQuery = doc(db, 'users', uid);
          const userSnapshot = await getDoc(userQuery);
    
          if (userSnapshot.exists()) {
            const user = userSnapshot.data();
    
            // Initialize counters
            let counter1Value = 0;
            let counter2Value = 0;
    
            // Get current month index and previous month index
            const currentMonthIndex = new Date().getMonth();
            const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
    
            if (bname) {
              // Iterate through additionalData array to find matching bname
              user.additionalData.forEach((data) => {
                if (data.bname === bname) {
                  // If matching bname found, set counter values
                  counter1Value = data.counter1 || 0;
                  counter2Value = data.counter2 || 0;
                }
              });
            } else {
              // If bname is not provided, use uid to get counter values
              counter1Value = user.counter1 || 0;
              counter2Value = user.counter2 || 0;
            }
    
            // Update data state
            setData((prevData) => {
              const newData = prevData.map((monthData, index) => {
                let newCounter1 = monthData.Counter1;
                let newCounter2 = monthData.Counter2;
    
                if (index === currentMonthIndex) {
                  newCounter1 = counter1Value;
                }
    
                if (index === previousMonthIndex) {
                  newCounter2 = counter2Value;
                }
    
                return {
                  ...monthData,
                  Counter1: newCounter1,
                  Counter2: newCounter2,
                };
              });
    
              return newData;
            });
    
          } else {
            console.error("User document not found.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
    
    fetchData();
  }, [uid, bname]);    

  return (
    <div className="chart">
      <div className="title-container">
      <div className="title">Total Users</div>
      <div className="title1">Total Customers</div>
      <div className="title2">Operating Status</div>
      <div className="title3">|</div>
      <div className="current-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="#1C1C1C"/>
      </svg>
      <div className="title4">Current Month</div>
      </div>
      <div className="current-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="#A8C5DA"/>
      </svg>
      <div className="title5">Previous Month</div>
      </div>
      </div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="name" stroke="gray" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Counter1"
            stroke="#1C1C1C"
            fillOpacity={1}
            fill="url(#total1)"
          />
          <Area
            type="monotone"
            dataKey="Counter2"
            stroke="#A8C5DA"
            fillOpacity={1}
            fill="url(#total2)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;