import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import ListPro from "./pages/list-product/List-product";
import ListOrders from "./pages/list-orders/List-orders";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Product from "./pages/product/Product";
import UpdateProduct from "./pages/update-product/Update-Product";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs, profileInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import SingleProduct from "./pages/single-product/Single-product";
import SingleOrder from "./pages/single-order/Single-order";
import Start from "./pages/start/Start";
import SignUp from "./pages/signup/Signup";
import UpdateProfile from "./pages/profile/profile";
import Referal from "./pages/referal/referal";
import SearchB from "./pages/search/search";
import AboutUs from "./pages/about_us/about_us";
import Terms from "./pages/terms/terms";
import ListItem from "./pages/admin/admin";
import Contact from "./pages/contact/contact";
import Chatbot from "./pages/chat-bot/chat-bot";
import ChatBrandBot from "./pages/chat-bot/chat-bot";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const {currentUser} = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/start" />;
  };

  const RequireUsersAccess = ({ children }) => {
    const allowedUids = ["ROrGvVOWqSa7cw7O6nUnzed18Pe2", "PYfF3qKRJnTUbmf2j5qlA69cpgU2", "cYUHsL9bc0g7mEAAznMCrVtUrCn1"]; // Replace with your UIDs
  
    // Check if the current user exists and has one of the allowed UIDs
    const isAuthorized = currentUser && allowedUids.includes(currentUser.uid);
  
    return isAuthorized ? children : null;
  };  

  return (
    
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
          <Route path="start" element={<Start />} />
            <Route path="login" element={<Login />} />
            <Route path="search" element={<SearchB />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="terms" element={<Terms />} />
            <Route path="help" element={<Contact />} />
            <Route path="chatbot" element={<ChatBrandBot />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="signup" element={<SignUp />} />
            <Route path="users">
              <Route
                index
                element={
                  <RequireUsersAccess>
                    <ListItem />
                  </RequireUsersAccess>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireUsersAccess>
                    <Single />
                  </RequireUsersAccess>
                }
              />
              <Route
                path="new"
                element={
                  <RequireUsersAccess>
                    <New inputs={userInputs} title="Add New User" />
                  </RequireUsersAccess>
                }
              />
            </Route>
            <Route path="products">
              <Route
                index
                element={
                  <RequireUsersAccess>
                    <ListPro />
                  </RequireUsersAccess>
                }
              />
              <Route
                path=":productId"
                element={
                  <RequireUsersAccess>
                    <SingleProduct />
                  </RequireUsersAccess>
                }
              />
              <Route
                path="update/:productId"
                element={
                  <RequireUsersAccess>
                    <UpdateProduct inputs={productInputs} title="Update Product"/>
                  </RequireUsersAccess>
                }
              />
              <Route
                path="new"
                element={
                  <RequireUsersAccess>
                    <Product inputs={productInputs} title="Add New Product" />
                  </RequireUsersAccess>
                }
              />
            </Route>
            <Route path="orders">
              <Route
                index
                element={
                  <RequireUsersAccess>
                    <ListOrders />
                  </RequireUsersAccess>
                }
              />
              <Route
                path=":orderId"
                element={
                  <RequireUsersAccess>
                    <SingleOrder />
                  </RequireUsersAccess>
                }
              />
            </Route>
            <Route path="profile">
              <Route
                index
                element={
                  <RequireAuth>
                    <UpdateProfile inputs={profileInputs} title="Update Store Profile"/>
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="referal">
              <Route
                index
                element={
                  <RequireAuth>
                    <Referal inputs={profileInputs} title="Referal"/>
                  </RequireAuth>
                }
              />
            </Route>
            {/* <Route path="chatbot">
              <Route
                index
                element={
                  <RequireAuth>
                    <ChatBrandBot inputs={profileInputs} title="Chat bot"/>
                  </RequireAuth>
                }
              />
            </Route> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
