import "./terms.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SliderC from "../../components/logo-slider/slider";
import {
  collection,
  onSnapshot
} from "firebase/firestore";
import { db } from "../../firebase";
import RotatingSphere from "../../components/sphere/sphere";
import { Canvas } from "react-three-fiber";
import Sidebar from 'react-sidebar';

const Terms = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleSearch = () => {
    navigate("/search");
  };

  const handleHome = () => {
    navigate("/start");
    window.scrollTo(0, 0)
  };

  const handleAboutUs = () => {
    navigate("/about");
    window.scrollTo(0, 0)
  };

  const handleHelp = () => {
    navigate("/help");
    window.scrollTo(0, 0)
  };

  const handleChatbot = () => {
    navigate("/chatbot");
    window.scrollTo(0, 0)
  };

  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountry(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Filter users based on the user's country and payment status
      const filteredUsers = usersData.filter(user => {
        const mainUserDataMatches = user.country === userCountry && user.payment === 'yes';
        
        // Check if additionalData exists and iterate over it
        if (user.additionalData) {
          const additionalDataMatches = user.additionalData.some(data => data.country === userCountry && data.payment === 'yes');
          return mainUserDataMatches || additionalDataMatches;
        }
  
        return mainUserDataMatches;
      });
  
      console.log('Filtered Users:', filteredUsers); // Log the filtered users
  
      setUsers(filteredUsers);
    });
  
    return () => unsubscribe();
  }, [userCountry]);  

  const fetchCountry = (latitude, longitude) => {
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserCountry(data.countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country:", error);
      });
  };

  const handleRedirect = (user) => {
    window.open(user.link, "_blank");
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const sidebarContent = (
    <div className="sidebar">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>  
        <button type="submit" className="home-text" onClick={handleChatbot}>Chat bot</button>
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        <button type="submit" className="home-text" onClick={handleAboutUs}>About Us</button>
        <button type="submit" className="home-text" onClick={handleHelp}>Help</button>
    </div>
  );

  const handleWorld = () => {
    // Scroll to the element with the id "scroll-target" with smooth behavior
    document.getElementById("scroll-target").scrollIntoView({ behavior: "smooth" });
  };  

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="Termscontainer">
      <div className="header-container">
      <div className="logo-img-container" onClick={handleHome}>
        <img src="/images/logo.png" alt="logo" className="logo"/>
        <div className="logo-name">BrandForgeX</div>
      </div>
      <div className="search">
          <input
            type="text"
            placeholder="Search a Business"
            className="search-box"
            onClick={handleSearch}
          />
          <div className="search-icon-container"  onClick={handleSearch}>
          <SearchOutlinedIcon className="search-icon"/>
          </div>
            <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="white"/>
            </svg>
            </button>
        </div>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
      <div className="login">
        <div className="button-container">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        </div>
      </div>
    </div>
    <div className="start-line"/>
    <div className="business">
        <div className="business1">
          <div className="business-text-container">
            <div className="bs-text-1">Terms and Conditions</div>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.20)",
              width: "90%",
              margin: "auto",
              marginBottom: "20px",
            }}
          />
        </div>
        <div className="about-text">

<div className="about-heading">1. Introduction</div>

Welcome to Brandforgex! These Terms and Conditions and Policies govern your access to and use of the Brandforgex platform and services. By accessing or using our platform, you agree to comply with these terms and policies. Please read them carefully.

<div className="about-heading">2. Account Registration</div>

<div className="about-sub">- You must be 18 years or older to create an account on Brandforgex.</div>
<div className="about-sub1">- You agree to provide accurate and complete information when creating your account.</div>
<div className="about-sub1">- You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</div>

<div className="about-heading">3. User Content</div>

<div className="about-sub">- By uploading content to Brandforgex, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute your content for the purposes of providing our services.</div>
<div className="about-sub1">- You retain ownership of your content and are solely responsible for its legality and appropriateness.</div>

<div className="about-heading">4. Payment and Subscription</div>

<div className="about-sub">- Users are required to pay a subscription fee of £1.99 per user to access and use the Brandforgex platform.</div>
<div className="about-sub1">- Payments are processed securely through our payment gateway. Users may choose monthly, quarterly, or annual billing cycles.</div>
<div className="about-sub1">- Subscription fees are non-refundable unless otherwise specified.</div>

<div className="about-heading">5. Use of the Platform</div>

<div className="about-sub">- Users agree not to use the Brandforgex platform for any illegal or unauthorized purposes.</div>
<div className="about-sub1">- Users must not engage in any activities that may disrupt or interfere with the proper functioning of the platform.</div>
<div className="about-sub1">- Brandforgex reserves the right to suspend or terminate user accounts that violate these terms and policies.</div>

<div className="about-heading">6. Privacy Policy</div>

<div className="about-sub">- Our Privacy Policy outlines how we collect, use, and disclose personal information. By using Brandforgex, you consent to the terms of our Privacy Policy.</div>

<div className="about-heading">7. Intellectual Property Rights</div>

<div className="about-sub">- Brandforgex retains all rights, title, and interest in and to the platform and its contents, including logos, trademarks, and proprietary materials.</div>
<div className="about-sub1">- Users may not reproduce, modify, or distribute any content from the platform without prior written consent from Brandforgex.</div>

<div className="about-heading">8. Limitation of Liability</div>

<div className="about-sub">- Brandforgex shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the platform.</div>
<div className="about-sub1">- Users agree to indemnify and hold Brandforgex harmless from any claims, losses, or damages resulting from their use of the platform.</div>

<div className="about-heading">9. Amendments</div>

<div className="about-sub">- Brandforgex reserves the right to update or modify these terms and policies at any time without prior notice.</div>
<div className="about-sub1">- Users are responsible for reviewing these terms periodically to stay informed of any changes.</div>

<div className="about-heading">10. Governing Law</div>

<div className="about-sub">- These terms and policies shall be governed by and construed in accordance with the laws of [Jurisdiction].</div>
<div className="about-sub1">- Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of The United Kingdom.</div>

<div className="about-heading">Contact Us</div>
<div class="about-sub">If you have any questions or concerns about these terms and policies, please contact us at <a href="mailto:info@brandforgex.com" style={{ textDecoration: 'underline' }}>info@brandforgex.com</a>.</div>

    </div>
    </div>
<div className="footer">
<div className="footer1">
<div className="ft-text-container">
<div className="footer-img-container">
        <img src="/images/logo.png" alt="logo" className="footer-logo" onClick={handleHome} style={{ cursor: 'pointer' }}/>
<div className="footer-logo-name" onClick={handleHome} style={{ cursor: 'pointer' }}>BrandForgeX</div>
</div>
<div className="copy-text">Copyright © 2024</div>
{/* <div className="copy-text">SC798823 - Incorporated on 13 February 2024</div> */}
<div className="copy-text">55 Kirkton Avenue, Glasgow, Scotland</div>
<div className="copy-text" onClick={handleScrollToTop}>By Creating an Account, it means you agree to our <span className="underline">Privacy Policy</span> and <span className="underline">Terms of Service</span></div>
</div>
<div
  style={{
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.20)',
    width: '90%',
    margin: 'auto',
  }}
/>
<div className="footer-text-container">
<div className="footer-help"  onClick={handleHome}>Home </div>
<div className="footer-link-container">
<div className="footer-help"  onClick={handleAboutUs}>About Us</div>
<div className="footer-help"  onClick={handleHelp}>Help</div>
</div>
</div>
</div>
</div>
</div>
  );
};

export default Terms;
