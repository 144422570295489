import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.scss';
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  getDocs
} from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from 'react';

const SliderC = () => {

  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountryByCoordinates(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  // const fetchCountry = () => {
  //   fetch("http://ip-api.com/json/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('Fetched country data:', data); // Log the fetched country data
  //       const countryName = data.country || "";
  //       setUserCountry(countryName);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting user's country:", error);
  //     });
  // };  

  const fetchCountryByCoordinates = (latitude, longitude) => {
    // You can use reverse geocoding API here to fetch country based on latitude and longitude
    // Example: Fetch country using Google Maps Geocoding API
    const apiKey = 'AIzaSyAUlvtxGpDfZvmYpvt02uwn8EunzwgSUk8';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Extract country from the response
        const country = data.results[0].address_components.find(
          (component) => component.types.includes("country")
        );
        const countryName = country ? country.long_name : "";
        // console.log(countryName)
        setUserCountry(countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country by coordinates:", error);
      });
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Filter users based on the user's country and payment status
      const filteredUsers = usersData.filter(user => {
        const normalizedUserCountry = user.country?.toLowerCase().replace(/\s/g, '');
        const normalizedAPIUserCountry = userCountry?.toLowerCase().replace(/\s/g, '');
  
        // Check if the user has no specified country and payment status is 'yes'
        // if (!normalizedAPIUserCountry && user.payment === 'yes') {
        //   return true; 
          // Include the user in the filtered list
        // }
  
        // Check if the user's country matches the API user's country and payment status is 'yes'
        const mainUserDataMatches = normalizedUserCountry && user.payment === 'yes'
  
        // Check if additionalData exists and iterate over it
        if (user.additionalData) {
          const additionalDataMatches = user.additionalData.some(data => {
            const normalizedDataCountry = data.country?.toLowerCase().replace(/\s/g, '');
            return normalizedDataCountry === normalizedAPIUserCountry && data.payment === 'yes';
          });
          return mainUserDataMatches || additionalDataMatches;
        }
  
        return mainUserDataMatches;
      });
  
      // console.log('Filtered Users:', filteredUsers); // Log the filtered users
  
      setUsers(filteredUsers);
    });
  
    return () => unsubscribe();
  }, [userCountry]);  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.max(Math.min(users.length, 5), 1),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.max(Math.min(users.length, 4), 1),
        },
      },
    ],
  };

  return (
    <div className="Slider">
      <Slider {...settings}>
         {/* Display placeholder images while users' data is loading */}
       {/* <img src="/images/airtel.svg" alt="logo" className="slidelogo" /> */}
        {/* <img src="/images/uba.svg" alt="logo" className="slidelogo" />
        <img src="/images/paga.svg" alt="logo" className="slidelogo" />
        <img src="/images/mtn.svg" alt="logo" className="slidelogo" />
        <img src="/images/dangote.svg" alt="logo" className="slidelogo" />
        <img src="/images/firstbank.svg" alt="logo" className="slidelogo" />
        <img src="/images/google.svg" alt="logo" className="slidelogo" />
        <img src="/images/ebay.svg" alt="logo" className="slidelogo" /> */}
        {/* Your slide content goes here */}
        {users?.map((user, userIndex) => (
          <div key={userIndex} className='hello'>
            {/* Main user image */}
            <img src={user.image} alt="logo" className="slidelogo" />
  
            {/* Additional user data */}
            {/* {user.additionalData && user.additionalData.map((data, dataIndex) => (
              <div key={dataIndex}> */}
                {/* Additional user image */}
                {/* <img src={data.image} alt="logo" className="slidelogo" />
              </div>
            ))} */}
          </div>
        ))}
      </Slider>
    </div>
  );  
};

export default SliderC;