import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { useEffect, useState,} from "react";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import ListOrders from "../../components/table-orders/Table-Orders";
import TableOrders from "../../components/table-orders/Table-Orders";

const SingleOrder = () => {
  const { orderId } = useParams();
  const [itemData, setItemData] = useState(null);

  console.log( orderId )

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if ( orderId ) {
          const userDoc = await getDoc(doc(db, "orders", orderId));
          
          if (userDoc.exists()) {
            setItemData({ id: userDoc.id, ...userDoc.data() });
          } else {
            console.log("User not found");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserData();
  
  }, [ orderId ]);  

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
          {/* <Link  to={`/products/update/${productId}`} style={{ textDecoration: "none" }}>
            <div className="editButton">Edit</div>
            </Link> */}
            <h1 className="title">Information</h1>
            <div className="item">
            {itemData?.image ? (
                <img src={itemData?.image} alt="" className="itemImg" />
              ) : (
                <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImg"
              />
              )}
              <div className="details">
                  <h1 className="itemTitle">{itemData?.user.name}</h1>
                  <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{itemData?.user.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone Number:</span>
                  <span className="itemValue">{itemData?.user.phoneNumber}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{itemData?.user.activeAddress.address1}, </span>
                  <span className="itemValue">{itemData?.user.activeAddress.address2}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{itemData?.user.activeAddress.country}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Total Price:</span>
                  <span className="itemValue">${itemData?.user.totalAmount}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Ordered Time:</span>
                  <span className="itemValue">
                    {itemData?.timestamp && (
                      format(itemData.timestamp.toDate(), "MMMM d, yyyy 'at' h:mm:ss a")
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Order Details</h1>
          <TableOrders/>
        </div>
      </div>
    </div>
  );
};

export default SingleOrder;
