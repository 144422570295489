import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const TableOrders = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    let isMounted = true; // Add a flag to track component mount status

    const fetchOrderDetails = async () => {
      try {
        if (orderId) {
          const orderDoc = await getDoc(doc(db, "orders", orderId));

          if (isMounted && orderDoc.exists()) {
            setOrderDetails({ id: orderDoc.id, ...orderDoc.data() });
          } else {
            console.log("Order not found");
          }
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();

    // Cleanup function to handle unmounting
    return () => {
      isMounted = false;
    };
  }, [orderId]);

  // useEffect(() => {
  //   console.log("OrderDetails:", orderDetails);
  // }, [orderDetails]);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Item ID</TableCell>
            <TableCell className="tableCell">Product</TableCell>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">Order Date</TableCell>
            <TableCell className="tableCell">Price</TableCell>
            <TableCell className="tableCell">Qunatity</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderDetails &&
            Array.isArray(orderDetails.cartItems) &&
            orderDetails.cartItems.map((item) => (
              <TableRow key={item?.id}>
                <TableCell className="tableCell">{item?.id}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img src={item?.image} alt="" className="image" />
                    {item?.name}
                  </div>
                </TableCell>
                <TableCell className="tableCell">{orderDetails.user.name}</TableCell>
                <TableCell className="tableCell">
                  {orderDetails?.timestamp && format(orderDetails?.timestamp.toDate(), "MMMM d, yyyy 'at' h:mm a")}
                </TableCell>
                <TableCell className="tableCell">${item?.price}</TableCell>
                <TableCell className="tableCell">{item?.quantity}</TableCell>
                <TableCell className="tableCell">{orderDetails?.paymentMethod}</TableCell>
                <TableCell className="tableCell">
                  <span className={`status ${orderDetails?.status}`}>{orderDetails?.status}</span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableOrders;