import "./search.scss";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SliderC from "../../components/logo-slider/slider";
import {
  collection,
  onSnapshot,
  getDocs,
  updateDoc
} from "firebase/firestore";
import { db } from "../../firebase";

const SearchB = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleHome = () => {
    navigate("/start");
    window.scrollTo(0, 0)
  };

  const handleAboutUs = () => {
    navigate("/about");
    window.scrollTo(0, 0)
  };

  const handleTerms = () => {
    navigate("/terms");
    window.scrollTo(0, 0)
  };

  const handleHelp = () => {
    navigate("/help");
    window.scrollTo(0, 0)
  };
  
  const handleChatbot = () => {
    navigate("/chatbot");
    window.scrollTo(0, 0)
  };

  const [searchInput, setSearchInput] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // Fetch categories from the database
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      if (snapshot && !snapshot.empty) {
        const categoriesData = [];
  
        snapshot.forEach((doc) => {
          const userData = doc.data();
  
          // If user's country is not available and payment status is 'yes', include all categories
          if (!userCountry && userData.payment === "yes" && userData.nbname && !categoriesData.some((category) => category.name === userData.nbname)) {
            categoriesData.push({ name: userData.nbname, type: 'category-bt2' });
          }
          
          // Extract category name from main user data if user's country and payment status match
          if (userData.nbname && userData.country === userCountry && userData.payment === "yes" && !categoriesData.some((category) => category.name === userData.nbname)) {
            categoriesData.push({ name: userData.nbname, type: 'category-bt2' });
          }
  
          // Extract category names from additional data if user's country and payment status match
          // if (userData.additionalData && Array.isArray(userData.additionalData)) {
          //   userData.additionalData.forEach((data) => {
          //     if (data.nbname && data.country === userCountry && data.payment === "yes" && !categoriesData.some((category) => category.name === data.nbname)) {
          //       categoriesData.push({ name: data.nbname, type: 'category-bt2' });
          //     }
          //   });
          // }
        });
  
        setCategories(categoriesData);
      }
    });
  
    return () => unsubscribe();
  }, [userCountry]);  

  const handleCategoryClick = (index) => {
    // Change the category button type for the clicked category
    const updatedCategories = categories.map((category, i) => ({
      ...category,
      type: i === index ? (category.type === 'category-bt1' ? 'category-bt2' : 'category-bt1') : 'category-bt2',
    }));
    setCategories(updatedCategories);
  };  
  
  useEffect(() => {
    // Toggle the category button type when there's a search input
    const updatedCategories = categories.map((category, index) => ({
      ...category,
      type: searchInput === "" ? (selectedCategory === index ? 'category-bt2' : category.type) : 'category-bt2',
    }));
    setCategories(updatedCategories);
  }, [searchInput]);
  

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountryByCoordinates(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  const fetchCountryByCoordinates = (latitude, longitude) => {
    // You can use reverse geocoding API here to fetch country based on latitude and longitude
    // Example: Fetch country using Google Maps Geocoding API
    const apiKey = 'AIzaSyAUlvtxGpDfZvmYpvt02uwn8EunzwgSUk8';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Extract country from the response
        const country = data.results[0].address_components.find(
          (component) => component.types.includes("country")
        );
        const countryName = country ? country.long_name : "";
        // console.log(countryName)
        setUserCountry(countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country by coordinates:", error);
      });
  };

  const handleRedirect = async (user) => {
    if (!user || !user.bname) {
        console.error('User data is missing or invalid.');
        return;
    }

    try {
        const userSnapshot = await getDocs(collection(db, 'users'));

        userSnapshot.forEach(async (doc) => {
            const userData = doc.data();

            // If the document has additionalData field, update its counters
            if (userData.additionalData) {
                userData.additionalData.forEach((item) => {
                    if (item.bname === user.bname) {
                        // If counter1 doesn't exist for the item, initialize it to 0
                        if (!item.counter1) {
                            item.counter1 = 0;
                        }

                        // If counter2 doesn't exist for the item, initialize it to 0
                        if (!item.counter2) {
                            item.counter2 = 0;
                        }

                        // Increment counter1 for the item and reset it if it's a new month
                        const today = new Date();
                        const currentMonth = today.getMonth();
                        if (item.lastUpdatedMonth !== currentMonth) {
                            item.counter2 = item.counter1;
                            item.counter1 = 0;
                            item.lastUpdatedMonth = currentMonth; // Update lastUpdatedMonth to the current month
                        }
                        item.counter1 += 1;
                    }
                });
            }

            // If the document itself matches the user's bname, update its counters
            if (userData.bname === user.bname) {
                // If counter1 doesn't exist for the item, initialize it to 0
                if (!userData.counter1) {
                    userData.counter1 = 0;
                }

                // If counter2 doesn't exist for the item, initialize it to 0
                if (!userData.counter2) {
                    userData.counter2 = 0;
                }

                // Increment counter1 for the item and reset it if it's a new month
                const today = new Date();
                const currentMonth = today.getMonth();
                if (userData.lastUpdatedMonth !== currentMonth) {
                    userData.counter2 = userData.counter1;
                    userData.counter1 = 0;
                    userData.lastUpdatedMonth = currentMonth; // Update lastUpdatedMonth to the current month
                }
                userData.counter1 += 1;
            }

            // Update the document with the updated data
            await updateDoc(doc.ref, userData);
        });

        console.log('Counters updated successfully!');
        // Check if user.link starts with https://, if not prepend https://
        const url = user.link.startsWith('https://') ? user.link : `https://${user.link}`;
        window.location.href = url;
    } catch (error) {
        console.error('Error updating counters:', error);
    }
};

useEffect(() => {
  const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
    const usersData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Filter users based on the user's country and payment status
    const filteredData = usersData.filter((user) => {
      const normalizedUserCountry = user.country?.toLowerCase().replace(/\s/g, '');
      const mainUserDataMatches =
        (!userCountry || normalizedUserCountry === userCountry?.toLowerCase().replace(/\s/g, '')) && user.payment === "yes";

      // Check if additionalData exists and iterate over it
      if (user.additionalData) {
        const additionalDataMatches = user.additionalData.some(
          (data) => {
            const normalizedAdditionalDataCountry = data.country?.toLowerCase().replace(/\s/g, '');
            return (!userCountry || normalizedAdditionalDataCountry === userCountry?.toLowerCase().replace(/\s/g, '')) && data.payment === "yes";
          }
        );
        return mainUserDataMatches || additionalDataMatches;
      }

      return mainUserDataMatches;
    });

    // If search input is empty, show all users from the same country; otherwise, apply search filtering
    const searchData = searchInput
      ? filteredData.filter((user) => {
          // Check if the business name matches the search input
          const mainUserDataMatches =
            user.bname.toLowerCase().includes(searchInput.toLowerCase()) || user.nbname.toLowerCase().includes(searchInput.toLowerCase());

          // Check if any additional data matches the search input
          const additionalDataMatches =
            user.additionalData &&
            user.additionalData.some(
              (data) =>
                data.bname.toLowerCase().includes(searchInput.toLowerCase())
            );

          // Return true if either the main user data or additional data matches the search input
          return mainUserDataMatches || additionalDataMatches;
        })
      : filteredData;

    // Filter based on selected category if one is selected
    const selectedCategory = categories.find((category) => category.type === 'category-bt1');
    const filteredByCategory = selectedCategory
      ? searchData.filter((user) => user.nbname.toLowerCase() === selectedCategory.name.toLowerCase())
      : searchData;

    setAllUsers(usersData);
    setFilteredUsers(filteredByCategory);
  });

  return () => unsubscribe();
}, [userCountry, searchInput, categories]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling behavior
    });
  };

  // Function to check if scroll position is at the bottom of the page and show/hide the button
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY || window.pageYOffset;
    const bodyHeight = document.body.offsetHeight;

    if (scrollY > 0.4 * (bodyHeight - windowHeight)) { // Show button when user is near the bottom (adjust percentage as needed)
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Effect hook to add/remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="search-container">
      <div className="header-container">
        <div className="logo-img-container" onClick={handleHome}>
          <img src="/images/logo.png" alt="logo" className="logo" />
          <div className="logo-name">BrandForgeX</div>
        </div>
        <div className="search">
          <input
            type="text"
            placeholder="Search a Business"
            className="search-box"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div className="search-icon-container">
            <SearchOutlinedIcon className="search-icon" />
          </div>
        </div>
        <div className="login">
          <div className="button-container">
            <button type="submit" className="home-text" onClick={handleHome}>
              Home
            </button>
            <button type="submit" className="home-text2" onClick={handleChatbot}>Chatbot</button>  
            <button type="submit" className="button1" onClick={handleLogin}>
              Login
            </button>
            <button type="submit" className="button2" onClick={handleSignUp}>
              Signup
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: "0.5px solid rgba(255, 255, 255, 0.20)",
          width: "90%",
          margin: "auto",
        }}
      />
      <div className="business">
      <div className="business-text-container">
            <div className="bs-text-1">Businesses</div>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.20)",
              width: "90%",
              margin: "auto",
              marginBottom: "20px",
            }}
          />
                    {filteredUsers.length === 0 ? (
          <div className="no-business">No businesses available in your country</div>
        ) : (
          <>
           <div className="category-container">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={category.type}
                  onClick={() => handleCategoryClick(index)}
                >
                  {category.name}
                </button>
              ))}
            </div>
          <div
            className="arrow-bro"
          />
        <div className="business1">
          <div className="business-world-container">
          <div className="business-world1-container">
  {/* Map through filteredUsers and render all user data together */}
  {filteredUsers.map((user, userIndex) => (
    <React.Fragment key={userIndex}>
      {/* Display user information */}
      <div className="business-logo-container">
        <img src={user.image} alt={`logo-${userIndex}`} className="bs-logo" />
        <div className="bs-name">{user.bname}</div>
        <button className="bs-button" onClick={() => handleRedirect(user)}>
          Visit
        </button>
      </div>

      {/* Display additional data if available */}
      {user.additionalData && user.additionalData.map((data, dataIndex) => (
                data.payment === "yes" && (
        <div key={dataIndex} className="business-logo-container">
          <img src={data.image} alt={`additional-logo-${userIndex}-${dataIndex}`} className="bs-logo" />
          <div className="bs-name">{data.bname}</div>
          <button className="bs-button" onClick={() => handleRedirect(data)}>
            Visit
          </button>
        </div>
                )
      ))}
    </React.Fragment>
  ))}
</div>
          </div>
        </div>
        </>
        )}
      </div>
      <div className={`top-up ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
      <span className="arrow" role="img" aria-label="arrow">↑</span>
    </div>
      <div className="footer">
        <div className="footer1">
          <div className="ft-text-container">
            <div className="footer-img-container">
              <img
                src="/images/logo.png"
                alt="logo"
                className="footer-logo"
                onClick={handleScrollToTop} style={{ cursor: 'pointer' }}
              />
              <div className="footer-logo-name" onClick={handleScrollToTop} style={{ cursor: 'pointer' }}>BrandForgeX</div>
            </div>
            <div className="copy-text">Copyright © 2024</div>
{/* <div className="copy-text">SC798823 - Incorporated on 13 February 2024</div> */}
<div className="copy-text">55 Kirkton Avenue, Glasgow, Scotland</div>
            <div className="copy-text" style={{ cursor: 'pointer' }} onClick={handleTerms}>By Creating an Account, it means you agree to our <span className="underline">Privacy Policy</span> and <span className="underline">Terms of Service</span></div>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.20)",
              width: "90%",
              margin: "auto",
            }}
          />
          <div className="footer-text-container">
          <div className="footer-help" onClick={handleHome}>Home </div>
            <div className="footer-link-container">
              <div className="footer-help" onClick={handleAboutUs}>About Us</div>
              <div className="footer-help" onClick={handleHelp}>Help</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchB;