import React, { useContext, useState, useEffect } from "react";
import "./login.scss";
import { signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Sidebar from 'react-sidebar';
import { CircularProgress } from '@mui/material';

const Login = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    navigate("/signup");
  };

  const handleSearch = (e) => {
    navigate("/search");
  };

  const handleHome = () => {
    navigate("/start");
    window.scrollTo(0, 0)
  };

  const handleTerms = () => {
    navigate("/terms");
    window.scrollTo(0, 0)
  };

  const handleAboutUs = () => {
    navigate("/about");
    window.scrollTo(0, 0)
  };

  const handleHelp = () => {
    navigate("/help");
    window.scrollTo(0, 0)
  };

  const handleChatbot = () => {
    navigate("/chatbot");
    window.scrollTo(0, 0)
  };

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true on login attempt
  
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user.emailVerified) {
          dispatch({ type: "LOGIN", payload: user });
          navigate("/");
        } else {
          const shouldResend = window.confirm(
            "Your email is not verified. Do you want to resend the email verification link?"
          );
          if (shouldResend) {
            sendEmailVerification(user)
              .then(() => {
                alert("Email verification link resent successfully.");
              })
              .catch((error) => {
                console.error("Error resending verification email:", error);
                alert("Failed to resend verification email. Please try again later.");
              });
          } else {
            setError(true);
            alert("Please verify your email before logging in.");
          }
        }
      })
      .catch((error) => {
        setError(true);
        alert("Wrong email or password!");
      })
      .finally(() => {
        setLoading(false); // Set loading state to false after login attempt completes
      });
  };  
  
  const handleForgotPassword = (e) => {
    e.stopPropagation();
    if (!email) {
      setError(true);
      alert("Please enter the email address.");
      return;
    }
  
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password reset email sent. Check your inbox.");
      })
      .catch((error) => {
        setError(true);
        console.error("Error sending password reset email:", error.message);
      });
  };  

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const sidebarContent = (
    <div className="sidebar">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>  
        <button type="submit" className="home-text" onClick={handleChatbot}>Chat bot</button>    
        <button type="submit" className="button5">Login</button>
        <button type="submit" className="button6" onClick={handleSignUp}>Signup</button>
        <button type="submit" className="home-text" onClick={handleAboutUs}>About Us</button>
        <button type="submit" className="home-text" onClick={handleHelp}>Help</button>
    </div>
  );

  const [inputType, setInputType] = useState('password');

  // const toggleToPassword = () => {
  //   setInputType('password');
  // };

  // const toggleToText = () => {
  //   setInputType('text');
  // };

  const toggleVisibility = () => {
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  return (
    <div className="container1">
      <div className="login-image-container">
      <div className="circle-container">
        <img src="/images/outter-circle.svg" alt="login" className="outer-circle"/>
        <div className="inner-circle-container">
          <img src="/images/inner-circle.svg" alt="login" className=""/>
        </div>
      </div>
      <div className="login-image-text">
        <div className="login-text1">Connect to your direct audience</div>
        <div className="login-text2">Everything you need for your business to gain visibility</div>
      </div>
      </div>
      <div className="login">
      <div className="logo-img-container">
        <img src="/images/brand-logo.png" alt="logo" className="logo1" onClick={handleHome} style={{ zIndex: '1', cursor: 'pointer' }}/>
        <img src="/images/logo.png" alt="logo" className="logo2" onClick={handleHome} style={{ zIndex: '1', cursor: 'pointer' }}/>
        <div className="logo-name" onClick={handleHome} style={{ zIndex: '1', cursor: 'pointer' }}>BrandForgeX</div>
            <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="white"/>
            </svg>
            </button>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
      </div>
        <div className="text-container">
          <div className="text1">Login to your account</div>
          <div className="text2">Welcome back, please enter your details below.</div>
        </div>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div>
          <div className="password-container">
          <input
            type={inputType}
            placeholder="Enter password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="password-toggle" onClick={toggleVisibility}>
          {inputType === 'password' ? <img src="/images/eye.png" alt="eye" className="eye-logo" /> : <img src="/images/hide.png" alt="eye" className="eye-logo" />}
        </span>
        </div>
        </div>
          <div className="idk">
          <p className="input-header" onClick={(e) => handleForgotPassword(e)}>Forgot Password?</p>
          </div>
          <div className="button-container">
              <button type="submit" className="button2">
            {/* Display circular progress bar if loading */}
            {loading ? (
               <CircularProgress
               size={24}
               color="inherit"
               style={{ marginRight: 8 }} // Adjust spacing if needed
             />        
                ) : (
                    'Log In'
              )}
              </button>
          </div>
          {/* {error && <span>Wrong email or password!</span>} */}
        </form>
        <div className="text4" onClick={handleTerms}>By continuing, you agree to our <span className="underline">Privacy Policy</span> and <span className="underline">Terms of Service</span></div>
        <div className="text3" onClick={handleSignUp}>Don’t have an account? <span className="login-link">Register</span></div>
      </div>
    </div>
  );
};

export default Login;