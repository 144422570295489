import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useParams } from "react-router-dom";
import { getDoc, doc, getDocs, collection } from "firebase/firestore";
import { useEffect, useState,} from "react";
import { db } from "../../firebase";
// import { Link } from "react-router-dom";

const Single = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [userAddresses, setUserAddresses] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (userId) {
          const userDoc = await getDoc(doc(db, "users", userId));

          if (userDoc.exists()) {
            setUserData({ id: userDoc.id, ...userDoc.data() });

            // Fetch addresses for the user
            const addressSnapshot = await getDocs(collection(db, "users", userId, "address"));
            const addressesData = addressSnapshot.docs.map(addressDoc => ({
              id: addressDoc.id,
              ...addressDoc.data(),
            }));

            setUserAddresses(addressesData);
          } else {
            console.log("User not found");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();

  }, [userId]);
  // useEffect(() => {
  //   console.log("UserData:", userData);
  // }, [userData]);  

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {/* <Link to={`/users/new`} style={{ textDecoration: "none" }}>
            <div className="editButton">Edit</div>
            </Link> */}
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle">{userData?.name}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{userData?.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{userData?.phoneNumber}</span>
                </div>
                <div className="detailItem">
            <span className="itemKey">Address:</span>
            <span className="itemValue">
                      {userAddresses.length > 0
            ? userAddresses.map(address => (
                address.activeAddress &&
                `${address.address1 || ''}, ${address.address2 || ''}`
              )).find(Boolean) || "No Address"
            : "No Address"}
            </span>
          </div>
          <div className="detailItem">
            <span className="itemKey">Country:</span>
            <span className="itemValue">
            {userAddresses.length > 0
            ? userAddresses.map(address => (
                address.activeAddress &&
                address.country
              )).find(Boolean) || "No Country"
            : "No Country"}
            </span>
          </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <List/>
        </div>
      </div>
    </div>
  );
};

export default Single;
