export const userInputs = [
    {
      id: "username",
      label: "Username",
      type: "text",
      placeholder: "john_doe",
    },
    {
      id: "email",
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
    },
    {
      id: "address",
      label: "Address",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
    },
  ];
  
  export const productInputs = [
    {
      label: "Name",
      id: "name",
      type: "text",
      placeholder: "Apple",
    },
    {
      id: "amount",
      label: "Quantity",
      type: "text",
      placeholder: "1Kg, Price",
    },
    {
      id: "Category",
      label: "Category",
      type: "text",
      placeholder: "Vegetables",
    },
    {
      id: "Culture",
      label: "Culture",
      type: "text",
      placeholder: "African",
    },
    {
      id: "description",
      label: "Description",
      type: "text",
      placeholder: "Description about the product",
    },
    {
      id: "price",
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: "stock",
      label: "Stock",
      type: "select",
      options: [
        { value: "In Stock", label: "In Stock" },
        { value: "Out of Stock", label: "Out of Stock" },
      ],
    },
  ];  

  export const profileInputs = [
    {
      label: "Business Name",
      id: "bname",
      type: "text",
      placeholder: "Business Name",
    },
    {
      id: "email",
      label: "Business Email",
      type: "email",
      placeholder: "Business Email",
    },
    {
      id: "link",
      label: "Business Link",
      type: "text",
      placeholder: "Business Link",
    },
  ];

  export const profileAdditionalInputs = [
    {
      label: "Business Name",
      id: "bname",
      type: "text",
      placeholder: "Business Name",
    },
    {
      id: "email",
      label: "Business Email",
      type: "email",
      placeholder: "Business Email",
    },
    {
      id: "link",
      label: "Business Link",
      type: "text",
      placeholder: "Business Link",
    },
  ];
  