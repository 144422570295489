import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { ordersColumns, } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";

const DatatableOrders = () => {
  const [data, setData] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid

  useEffect(() => {
    // const fetchData = async () => {
    //   let list = [];
    //   try {
    //     const querySnapshot = await getDocs(collection(db, "users"));
    //     querySnapshot.forEach((doc) => {
    //       list.push({ id: doc.id, ...doc.data() });
    //     });
    //     setData(list);
    //     console.log(list);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchData();
  
    // LISTEN (REALTIME)
    if (uid) {
      const unsubscribe = onSnapshot(
        collection(db, "orders"),
        (snapshot) => {
          let list = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            // Check if uid is the special one
            if (uid === "QuYLksMLriOhMtUcYo7TGCNrRUh1" || data.storeId === uid) {
              list.push({ id: doc.id, ...data });
            }
          });
          setData(list);
        },
        (error) => {
          console.error("Error fetching data:", error);
        }
      );
  
      return () => unsubscribe();
    }
  }, [uid]);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "orders", id));
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/orders/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable2">
      <div className="datatableTitle">
        Orders
        {/* <Link to="/products/new" className="link">
          Add New
        </Link> */}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={ordersColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default DatatableOrders;
