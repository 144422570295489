import "./navbar.scss";
import { useContext, useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import Sidebar from 'react-sidebar';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../firebase";

const Navbar = ({ bname }) => {
  // const { dispatch } = useContext(DarkModeContext);
  const { currentUser } = useContext(AuthContext);

  const uid = currentUser?.uid

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
        try {
            if (!uid) {
                // If uid is not available, return early
                return;
            }

            if (uid === "QuYLksMLriOhMtUcYo7TGCNrRUh1") {
                // Special case for the specific uid
                setUserDetails({
                    name: "Groce Admin",
                    email: "groce@admin",
                });
                return;
            }

            const userDoc = await getDoc(doc(db, "users", uid));
            if (!userDoc.exists) {
                // If user document doesn't exist, handle the scenario
                console.log("User not found in the database");
                return;
            }

            const userData = userDoc.data();
            setUserDetails(userData);

            console.log(bname)

            if (bname && Array.isArray(userData.additionalData)) {
                const bnameExists = userData.additionalData.find(item => item.bname === bname);
                setUserImage(bnameExists?.image || userData.image); // Set user image from additional data if bname matches, otherwise fallback to default image
            } else {
                setUserImage(userData?.image); // Set user image from userData
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    fetchUserDetails();
}, [uid, bname]);
  
  const handleLogout = async (e) => {
    e.preventDefault();
  
    try {
      // Sign out from Firebase
      await auth.signOut();
  
      // Update the authentication state in the context
      dispatch({ type: "LOGOUT" });
  
      // Redirect to the login page
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  // console.log("User details from SomeOtherComponent:", currentUser);

  const handleSearch = (query) => {
    // Redirect to the products page with the search query
    navigate(`/products?search=${query}`);
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // const { dispatch } = useContext(DarkModeContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();

  // List of allowed UIDs
  const allowedUids = ["ROrGvVOWqSa7cw7O6nUnzed18Pe2", "PYfF3qKRJnTUbmf2j5qlA69cpgU2", "cYUHsL9bc0g7mEAAznMCrVtUrCn1"];

  // Check if the current user's UID is included in the list of allowed UIDs
  const isUserAllowed = currentUser && allowedUids.includes(currentUser.uid);

  const sidebarContent = (
    <div className="sidebar">
      <div className="center">
        <ul>
          {/* <p className="title">MAIN</p> */}
          <Link
            to="/"
            style={{
              textDecoration: "none",
              marginTop:
                location.pathname === "/" ||
                location.pathname === "/products" ||
                location.pathname === "/users" ||
                location.pathname === "/orders" ||
                location.pathname === "/referal" ||
                location.pathname === "/chatbot" ||
                location.pathname === "/profile" ||
                location.pathname.startsWith("/users/") ||
                location.pathname.startsWith("/products/") ||
                location.pathname.startsWith("/profile/") ||
                location.pathname.startsWith("/referal/") ||
                location.pathname.startsWith("/chatbot/") ||
                location.pathname.startsWith("/orders/")
                  ? "20px"
                  : undefined,
            }}
            >
          <div  className={location.pathname === "/" ? "title" : ""}>
          <li style={{ marginLeft: location.pathname !== "/" ? '30px' : '30px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill={location.pathname === "/" ? "#fff" : "rgba(255, 255, 255, 0.63)"}
            className="icon"
          >
            <path d="M16 2.5V4.5H12V2.5H16ZM6 2.5V8.5H2V2.5H6ZM16 10.5V16.5H12V10.5H16ZM6 14.5V16.5H2V14.5H6ZM18 0.5H10V6.5H18V0.5ZM8 0.5H0V10.5H8V0.5ZM18 8.5H10V18.5H18V8.5ZM8 12.5H0V18.5H8V12.5Z" />
          </svg>
            <span className={location.pathname === "/" ? "current" : ""}>Dashboard</span>
          </li>
          </div>
          </Link>
          {/* <p className="title">LISTS</p> */}
          {/* <Link
              to="/start"
              style={{
                textDecoration: "none",
                marginTop:
                  location.pathname === "/users" ||
                  location.pathname === "/products" ||
                  location.pathname === "/orders" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/referal" ||
                  location.pathname === "/signup" ||
                  location.pathname === "/start" ||
                  location.pathname === "/" ||
                  location.pathname.startsWith("/users/") ||
                  location.pathname.startsWith("/products/") ||
                  location.pathname.startsWith("/orders/") ||
                  location.pathname.startsWith("/profile/") ||
                  location.pathname.startsWith("/referal/") ||
                  location.pathname.startsWith("/signup/") ||
                  location.pathname.startsWith("/start/")
                    ? "20px"
                    : undefined,
              }}
            >
            <div className={location.pathname === "/start" || location.pathname.startsWith("/start/") ? "title" : ""}>
            <li style={{ marginLeft: location.pathname !== "/start" && !location.pathname.startsWith("/start/") ? '30px' : '30px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25"
            height="25" viewBox="0 0 24 24"
            fill={location.pathname === "/start" || location.pathname.startsWith("/start/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}
            className="icon"
            style={{ marginLeft: "0px" }}
            >
                <path d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 10 21 L 10 14 L 14 14 L 14 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z"></path>
            </svg> */}
            {/* <AccountCircleOutlinedIcon /> */}
            {/* <span className={location.pathname === "/start" || location.pathname.startsWith("/start/") ? "current" : ""}>Home</span>
          </li>
          </div>
          </Link> */}
          <Link
              to="/profile"
              style={{
                textDecoration: "none",
                marginTop:
                  location.pathname === "/users" ||
                  location.pathname === "/products" ||
                  location.pathname === "/orders" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/referal" ||
                  location.pathname === "/chatbot" ||
                  location.pathname === "/" ||
                  location.pathname.startsWith("/users/") ||
                  location.pathname.startsWith("/products/") ||
                  location.pathname.startsWith("/orders/") ||
                  location.pathname.startsWith("/referal/") ||
                  location.pathname.startsWith("/chatbot/") ||
                  location.pathname.startsWith("/profile/")
                    ? "20px"
                    : undefined,
              }}
            >
            <div className={location.pathname === "/profile" || location.pathname.startsWith("/profile/") ? "title" : ""}>
            <li style={{ marginLeft: location.pathname !== "/profile" && !location.pathname.startsWith("/profile/") ? '30px' : '30px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill={location.pathname === "/profile" || location.pathname.startsWith("/profile/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}
            className="icon"
          >
            <path d="M3 14.5C3 12.5 7 11.4 9 11.4C11 11.4 15 12.5 15 14.5V15.5H3M12 6.5C12 7.29565 11.6839 8.05871 11.1213 8.62132C10.5587 9.18393 9.79565 9.5 9 9.5C8.20435 9.5 7.44129 9.18393 6.87868 8.62132C6.31607 8.05871 6 7.29565 6 6.5C6 5.70435 6.31607 4.94129 6.87868 4.37868C7.44129 3.81607 8.20435 3.5 9 3.5C9.79565 3.5 10.5587 3.81607 11.1213 4.37868C11.6839 4.94129 12 5.70435 12 6.5ZM0 2.5V16.5C0 17.0304 0.210714 17.5391 0.585786 17.9142C0.960859 18.2893 1.46957 18.5 2 18.5H16C16.5304 18.5 17.0391 18.2893 17.4142 17.9142C17.7893 17.5391 18 17.0304 18 16.5V2.5C18 1.96957 17.7893 1.46086 17.4142 1.08579C17.0391 0.710714 16.5304 0.5 16 0.5H2C0.89 0.5 0 1.4 0 2.5Z" />
          </svg>
            <span className={location.pathname === "/profile" || location.pathname.startsWith("/profile/") ? "current" : ""}>Profile</span>
          </li>
          </div>
          </Link>
          <Link
              to="/referal"
              style={{
                textDecoration: "none",
                marginTop:
                  location.pathname === "/users" ||
                  location.pathname === "/products" ||
                  location.pathname === "/orders" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/referal" ||
                  location.pathname === "/chatbot" ||
                  location.pathname === "/" ||
                  location.pathname.startsWith("/users/") ||
                  location.pathname.startsWith("/products/") ||
                  location.pathname.startsWith("/orders/") ||
                  location.pathname.startsWith("/profile/") ||
                  location.pathname.startsWith("/chatbot/") ||
                  location.pathname.startsWith("/referal/")
                    ? "20px"
                    : undefined,
              }}
            >
            <div className={location.pathname === "/referal" || location.pathname.startsWith("/referal/") ? "title" : ""}>
            <li style={{ marginLeft: location.pathname !== "/referal" && !location.pathname.startsWith("/referal/") ? '30px' : '30px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill={location.pathname === "/referal" || location.pathname.startsWith("/referal/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}
            className="icon"
          >
            <path d="M14 13.0001V15.0001H0V13.0001C0 13.0001 0 9.00005 7 9.00005C14 9.00005 14 13.0001 14 13.0001ZM10.5 3.50005C10.5 2.80782 10.2947 2.13113 9.91014 1.55556C9.52556 0.979985 8.97893 0.531381 8.33939 0.266474C7.69985 0.00156766 6.99612 -0.067744 6.31718 0.0673043C5.63825 0.202353 5.01461 0.535695 4.52513 1.02518C4.03564 1.51466 3.7023 2.1383 3.56725 2.81724C3.4322 3.49617 3.50152 4.1999 3.76642 4.83944C4.03133 5.47899 4.47993 6.02561 5.0555 6.4102C5.63108 6.79478 6.30777 7.00005 7 7.00005C7.92826 7.00005 8.8185 6.6313 9.47487 5.97493C10.1313 5.31855 10.5 4.42831 10.5 3.50005ZM13.94 9.00005C14.5547 9.4758 15.0578 10.0805 15.4137 10.7716C15.7696 11.4626 15.9697 12.2233 16 13.0001V15.0001H20V13.0001C20 13.0001 20 9.37005 13.94 9.00005ZM13 5.2579e-05C12.3117 -0.00378014 11.6385 0.202008 11.07 0.590053C11.6774 1.43879 12.0041 2.45634 12.0041 3.50005C12.0041 4.54377 11.6774 5.56132 11.07 6.41005C11.6385 6.7981 12.3117 7.00389 13 7.00005C13.9283 7.00005 14.8185 6.6313 15.4749 5.97493C16.1313 5.31855 16.5 4.42831 16.5 3.50005C16.5 2.57179 16.1313 1.68156 15.4749 1.02518C14.8185 0.368802 13.9283 5.2579e-05 13 5.2579e-05Z" />
          </svg>
            {/* <AccountCircleOutlinedIcon /> */}
            <span className={location.pathname === "/referal" || location.pathname.startsWith("/referal/") ? "current" : ""}>Referals</span>
          </li>
          </div>
          </Link>
          <Link
              to={{
                pathname: "/signup",
                search: `?id=${currentUser.uid}`,
              }}
              style={{
                textDecoration: "none",
                marginTop:
                  location.pathname === "/users" ||
                  location.pathname === "/products" ||
                  location.pathname === "/orders" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/referal" ||
                  location.pathname === "/chatbot" ||
                  location.pathname === "/signup" ||
                  location.pathname === "/" ||
                  location.pathname.startsWith("/users/") ||
                  location.pathname.startsWith("/products/") ||
                  location.pathname.startsWith("/orders/") ||
                  location.pathname.startsWith("/profile/") ||
                  location.pathname.startsWith("/referal/") ||
                  location.pathname.startsWith("/chatbot/") ||
                  location.pathname.startsWith("/signup/")
                    ? "20px"
                    : undefined,
              }}
            >
            <div className={location.pathname === "/signup" || location.pathname.startsWith("/signup/") ? "title" : ""}>
            <li style={{ marginLeft: location.pathname !== "/signup" && !location.pathname.startsWith("/signup/") ? '30px' : '30px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill={location.pathname === "/signup" || location.pathname.startsWith("/signup/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}
            className="icon"
          >
            <path d="M14 13.0001V15.0001H0V13.0001C0 13.0001 0 9.00005 7 9.00005C14 9.00005 14 13.0001 14 13.0001ZM10.5 3.50005C10.5 2.80782 10.2947 2.13113 9.91014 1.55556C9.52556 0.979985 8.97893 0.531381 8.33939 0.266474C7.69985 0.00156766 6.99612 -0.067744 6.31718 0.0673043C5.63825 0.202353 5.01461 0.535695 4.52513 1.02518C4.03564 1.51466 3.7023 2.1383 3.56725 2.81724C3.4322 3.49617 3.50152 4.1999 3.76642 4.83944C4.03133 5.47899 4.47993 6.02561 5.0555 6.4102C5.63108 6.79478 6.30777 7.00005 7 7.00005C7.92826 7.00005 8.8185 6.6313 9.47487 5.97493C10.1313 5.31855 10.5 4.42831 10.5 3.50005ZM13.94 9.00005C14.5547 9.4758 15.0578 10.0805 15.4137 10.7716C15.7696 11.4626 15.9697 12.2233 16 13.0001V15.0001H20V13.0001C20 13.0001 20 9.37005 13.94 9.00005ZM13 5.2579e-05C12.3117 -0.00378014 11.6385 0.202008 11.07 0.590053C11.6774 1.43879 12.0041 2.45634 12.0041 3.50005C12.0041 4.54377 11.6774 5.56132 11.07 6.41005C11.6385 6.7981 12.3117 7.00389 13 7.00005C13.9283 7.00005 14.8185 6.6313 15.4749 5.97493C16.1313 5.31855 16.5 4.42831 16.5 3.50005C16.5 2.57179 16.1313 1.68156 15.4749 1.02518C14.8185 0.368802 13.9283 5.2579e-05 13 5.2579e-05Z" />
          </svg>
          <p style={{ color: 'rgba(255, 255, 255, 0.63)', marginBottom: '5px', fontSize: "10px" }}>+</p>
            {/* <AccountCircleOutlinedIcon /> */}
            <span className={location.pathname === "/signup" || location.pathname.startsWith("/signup/") ? "current" : ""}>Add Brand</span>
          </li>
          </div>
          </Link>
          {/* <Link
              to="/chatbot"
              style={{
                textDecoration: "none",
                marginTop:
                  location.pathname === "/users" ||
                  location.pathname === "/products" ||
                  location.pathname === "/orders" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/referal" ||
                  location.pathname === "/chatbot" ||
                  location.pathname === "/" ||
                  location.pathname.startsWith("/users/") ||
                  location.pathname.startsWith("/products/") ||
                  location.pathname.startsWith("/orders/") ||
                  location.pathname.startsWith("/profile/") ||
                  location.pathname.startsWith("/referal/") ||
                  location.pathname.startsWith("/chatbot/")
                    ? "20px"
                    : undefined,
              }}
            >
            <div className={location.pathname === "/chatbot" || location.pathname.startsWith("/chatbot/") ? "title" : ""}>
            <li style={{ marginLeft: location.pathname !== "/chatbot" && !location.pathname.startsWith("/chatbot/") ? '30px' : '30px' }}>
            <svg 
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="24"
  viewBox="0 0 30 30"
  fill={location.pathname === "/chatbot" || location.pathname.startsWith("/chatbot/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}
  stroke="#000000"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
>
  <rect x="3" y="11" width="18" height="10" rx="2" />
  <circle cx="12" cy="5" r="2" />
  <path d="M12 7v4" />
  <line x1="8" y1="16" x2="8" y2="16" />
  <line x1="16" y1="16" x2="16" y2="16" />
</svg>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill={location.pathname === "/chatbot" || location.pathname.startsWith("/chatbot/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}
            className="icon"
          >
            <path d="M14 13.0001V15.0001H0V13.0001C0 13.0001 0 9.00005 7 9.00005C14 9.00005 14 13.0001 14 13.0001ZM10.5 3.50005C10.5 2.80782 10.2947 2.13113 9.91014 1.55556C9.52556 0.979985 8.97893 0.531381 8.33939 0.266474C7.69985 0.00156766 6.99612 -0.067744 6.31718 0.0673043C5.63825 0.202353 5.01461 0.535695 4.52513 1.02518C4.03564 1.51466 3.7023 2.1383 3.56725 2.81724C3.4322 3.49617 3.50152 4.1999 3.76642 4.83944C4.03133 5.47899 4.47993 6.02561 5.0555 6.4102C5.63108 6.79478 6.30777 7.00005 7 7.00005C7.92826 7.00005 8.8185 6.6313 9.47487 5.97493C10.1313 5.31855 10.5 4.42831 10.5 3.50005ZM13.94 9.00005C14.5547 9.4758 15.0578 10.0805 15.4137 10.7716C15.7696 11.4626 15.9697 12.2233 16 13.0001V15.0001H20V13.0001C20 13.0001 20 9.37005 13.94 9.00005ZM13 5.2579e-05C12.3117 -0.00378014 11.6385 0.202008 11.07 0.590053C11.6774 1.43879 12.0041 2.45634 12.0041 3.50005C12.0041 4.54377 11.6774 5.56132 11.07 6.41005C11.6385 6.7981 12.3117 7.00389 13 7.00005C13.9283 7.00005 14.8185 6.6313 15.4749 5.97493C16.1313 5.31855 16.5 4.42831 16.5 3.50005C16.5 2.57179 16.1313 1.68156 15.4749 1.02518C14.8185 0.368802 13.9283 5.2579e-05 13 5.2579e-05Z" />
          </svg> */}
            {/* <AccountCircleOutlinedIcon /> */}
            {/* <span className={location.pathname === "/chatbot" || location.pathname.startsWith("/chatbot/") ? "current" : ""}>Chat Bot</span>
          </li>
          </div>
          </Link> */}
          {isUserAllowed && (
          <Link
              to="/users"
              style={{
                textDecoration: "none",
                marginTop:
                  location.pathname === "/users" ||
                  location.pathname === "/products" ||
                  location.pathname === "/orders" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/referal" ||
                  location.pathname === "/chatbot" ||
                  location.pathname === "/users" ||
                  location.pathname === "/" ||
                  location.pathname.startsWith("/users/") ||
                  location.pathname.startsWith("/products/") ||
                  location.pathname.startsWith("/orders/") ||
                  location.pathname.startsWith("/profile/") ||
                  location.pathname.startsWith("/referal/") ||
                  location.pathname.startsWith("/chatbot/") ||
                  location.pathname.startsWith("/users/")
                    ? "20px"
                    : undefined,
              }}
            >
            <div className={location.pathname === "/users" || location.pathname.startsWith("/users/") ? "title" : ""}>
            <li style={{ marginLeft: location.pathname !== "/users" && !location.pathname.startsWith("/users/") ? '30px' : '30px' }}>
            <PersonOutlineIcon className="icon" fill={location.pathname === "/users" || location.pathname.startsWith("/users/") ? "#fff" : "rgba(255, 255, 255, 0.63)"}/>
            <span className={location.pathname === "/users" || location.pathname.startsWith("/users/") ? "current" : ""}>Users</span>
          </li>
          </div>
          </Link>
          )}
          <li style={{marginTop: '100%', alignItems: 'center', display: "flex", justifyContent: 'center', marginRight: '00px'}}>
          <ExitToAppIcon className="icon" style={{color: '#fff'}}/>
            <button onClick={handleLogout} className="logout">
            Logout
            </button>
          </li>
          {/* <li>
            <CreditCardIcon className="icon" />
            <span>Orders</span>
          </li>
          <li>
            <LocalShippingIcon className="icon" />
            <span>Delivery</span>
          </li>
          <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li>
          <p className="title">USER</p>
          */}
        </ul>
      </div>
    </div>
  );

  return (
    <div className="navbar">
      <div className="wrapper">
      {/* <div className="search">
          <input
            type="text"
            placeholder="Search Products"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch(e.target.value);
              }
            }}
          />
          <SearchOutlinedIcon onClick={() => handleSearch} />
        </div> */}
        <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="#1B40C4"/>
            </svg>
            </button>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
        <div className="brand-name-contianer">
          <p className="brand-name">Dashboard</p>
        </div>
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
          {/* <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div> */}
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div> */}
          {/* <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" /> */}
            {/* <div className="counter">1</div> */}
          {/* </div> */}
          {/* <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div> */}
          {/* <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            <a href="#" className="home-tag">Home</a>
          </div>
          <div className="item">
          {userImage && (
            <img src={userImage} alt="User Avatar" className="avatar" />
          // ) : (
          //   <img
          //     src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          //     alt=""
          //     className="avatar"
          //   />
            )}
            <div style={{ marginLeft: '5px' }}>
              {bname ? bname : userDetails?.bname}
            </div>
          </div>
          <div className="item">
            <div className="text-contianer">
            <div className="user-name">{userDetails?.name}</div>
            <div className="user-id">{userDetails?.email}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
