import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import "./chat-bot.scss";
import { AuthContext } from "../../context/AuthContext.js";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../firebase.js";
import { sendMsgToOpenAI } from './openai.ts';
import Sidebar from '../sidebar/Sidebar.jsx';

const Chatbot = ({ bname }) => {
  const msgEnd = useRef(null);
  const [messages, setMessages] = useState([
    {
    text: "Hi, I'm your buddy from BrandForgeX! Let's chat and make things awesome together!",
    isBot: true,
  }
]);

useEffect(() => {
  msgEnd.current.scrollIntoView();
}, [messages]);

  const [input, setInput] = useState('');

  const { currentUser } = useContext(AuthContext);

  const uid = currentUser?.uid

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
        try {
            if (!uid) {
                // If uid is not available, return early
                return;
            }

            if (uid === "QuYLksMLriOhMtUcYo7TGCNrRUh1") {
                // Special case for the specific uid
                setUserDetails({
                    name: "Groce Admin",
                    email: "groce@admin",
                });
                return;
            }

            const userDoc = await getDoc(doc(db, "users", uid));
            if (!userDoc.exists) {
                // If user document doesn't exist, handle the scenario
                console.log("User not found in the database");
                return;
            }

            const userData = userDoc.data();
            setUserDetails(userData);

            console.log(bname)

            if (bname && Array.isArray(userData.additionalData)) {
                const bnameExists = userData.additionalData.find(item => item.bname === bname);
                setUserImage(bnameExists?.image || userData.image); // Set user image from additional data if bname matches, otherwise fallback to default image
            } else {
                setUserImage(userData?.image); // Set user image from userData
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    fetchUserDetails();
}, [uid, bname]);

const handleSend = async () => {
  const text = input;
  setInput('');
  
  // Send user message to Python server
  const response = await axios.post('https://sauravtajith.pythonanywhere.com/process', 
    { message: text },
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*' // Add CORS header
      }
    }
  );

  // Parse the response from the Python server
  const data = response.data;
  const botResponse = data.response;

  // Update messages state with user message and bot response
  setMessages([
    ...messages,
    { text, isBot: false },
    { text: botResponse, isBot: true }
  ]);
};

const handleEnter = async (e) => {
  if( e.key === 'Enter') await handleSend();
}

  return (
    <div className="Chat">
      {/* <div className="sideBar"> */}
        {/* <div className="upperSide">
          <div className="upperSideTop">
          <img src="/images/logo.png" alt="Logo" className="logo" /><span className="brand">Chat Bot</span>
          </div>
          <button className="midBtn"><img src="/images/add-30.png" alt="new chat" className="addBtn" />New Chat</button>
          <div className="upperSideBottom">
            <button className="query"><img src="/images/message.svg" alt="Query" />What is brandforgex?</button>
            <button className="query"><img src="/images/message.svg" alt="Query" />What is brandforgex?</button>
          </div>
        </div>
        <div className="lowerSide">
          
          </div> */}
                      {/* <Sidebar />
      </div> */}
      <div className="main">
        <div className="chats">
          {/* <div className="chat">
            <img className='chatImg' src={userImage} alt="" /><p className="txt">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos consequatur, maiores minus doloribus numquam, fuga possimus, ea praesentium ab odio tempora ut animi deserunt. Molestias nemo ab, dolorum facilis hic veniam non eaque assumenda, perferendis eligendi modi, deserunt quam odit mollitia laudantium? Natus, illum! Minima quibusdam voluptate, placeat nesciunt quos odio eaque ullam facere natus beatae adipisci illum neque ipsa praesentium eos. Praesentium, porro quasi ipsam placeat facilis quas ea similique quidem tenetur nulla labore amet maxime provident culpa, veniam unde quis! Deserunt molestias necessitatibus vitae doloribus tenetur, labore, perferendis illo suscipit quis fuga natus, laborum dolore quia aut corporis.</p>
          </div>
          <div className="chat bot">
            <img className='chatImg1' src="/images/brand-logo.png" alt="" /><p className="txt">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis, reprehenderit nam consequuntur impedit incidunt illo temporibus possimus maxime sed earum, inventore hic quisquam delectus est commodi. Quaerat modi ipsa maxime tenetur numquam, enim quo aut. Harum possimus quia laboriosam itaque eligendi asperiores sint ipsam, perferendis laudantium modi minima iste voluptate quis a similique dolorum amet illo iusto dolor optio officiis magnam. Ad non nam accusantium, facere minima porro ullam nesciunt voluptate exercitationem aut amet vel ab tenetur saepe mollitia hic necessitatibus et maxime laudantium illo. Minima quis distinctio omnis error, provident, fuga dolorum et veritatis facere corporis est incidunt! Necessitatibus!</p>
          </div> */}
          {messages.map((message, i) => 
            <div key={i} className={ message.isBot ? "chat bot": "chat" }>
              <img className={ "chatImg1" } src={ message.isBot ? "/images/brand-logo.png": "/images/logo.png" } alt="" /><p className="txt">{ message.text }</p>
            </div>
          )}
          <div ref={msgEnd}/>
        </div>
        <div className="chatFooter">
          <div className="inp">
            <input type="text" placeholder='Send a message' value={input} onKeyDown={handleEnter} onChange={(e)=>{setInput(e.target.value)}} style={{ zIndex: '1' }}/> <button className="send" onClick={handleSend} style={{ zIndex: '1' }}><img src="/images/send.svg" alt="Send" /></button>
          </div>
          <p>Chat bot may produce inaccurate information about people, place, or facts.</p>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
