import React from "react";

export const userColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    renderCell: (params) => {
      // console.log('User Object:', params.row);
  
      const firstAddress = params.row.address;
  
      if (firstAddress) {
        // console.log('First Address:', firstAddress);
  
        const addressString = `${firstAddress.address1 || ''}, ${firstAddress.address2 || ''}, ${firstAddress.country || ''}`;
  
        // console.log('Address String:', addressString);
  
        return <div>{addressString}</div>;
      } else {
        return <div>No Address</div>;
      }
    },
  }  
  // Add other columns as needed
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];


export const productColumns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "bname",
    headerName: "Brand Name",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.image} alt="avatar" />
          {params.row.bname}
        </div>
      );
    },
  },
  {
    field: "address1",
    headerName: "Address",
    width: 230,
    renderCell: (params) => (
      <div>
        {params.value}
      </div>
    ),
  },

  {
    field: "payment",
    headerName: "Payment",
    width: 200,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];

export const ordersColumns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "User",
    headerName: "User",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {/* <img className="cellImg" src={params.row.cartItems.image} alt="avatar" /> */}
          {params.row.user.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    renderCell: (params) => (
      <div>
        {params.row.user.email}
      </div>
    ),
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
    renderCell: (params) => (
      <div>
        ${params.row.user.totalAmount}
      </div>
    ),
  },
  {
    field: "items",
    headerName: "Items",
    width: 250,
    renderCell: (params) => (
      <div className="cellWithImg">
        {params.row.cartItems.map((item, index) => (
          <React.Fragment key={item.id}>
            {item.name}
            {index < params.row.cartItems.length - 1 && ',\u00A0'}
          </React.Fragment>
        ))}
      </div>
    ),
  },  
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];