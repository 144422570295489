import "./admin.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import DatatablePro from "../../components/datatable-products/Datatable-pro"

const ListItem = () => {
  return (
    <div className="beast">
      <Sidebar/>
      <div className="listContainer1">
        <Navbar/>
        <DatatablePro/>
      </div>
    </div>
  )
}

export default ListItem