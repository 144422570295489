// RotatingSphere.js
import React, { useRef } from "react";
import { useFrame } from "react-three-fiber";
import { Sphere } from "@react-three/drei";
import { TextureLoader } from "three";

const RotatingSphere = () => {
  const sphereRef = useRef();

  // Load the texture
  const texture = new TextureLoader().load("/images/connection.jpg");

  useFrame(() => {
    // Rotate the sphere on each frame
    sphereRef.current.rotation.x += 0.005;
    sphereRef.current.rotation.y += 0.005;
  });

  return (
    <Sphere ref={sphereRef} args={[3, 32, 32]}>
      <meshStandardMaterial attach="material" map={texture} />
    </Sphere>
  );
};

export default RotatingSphere;