// import OpenAI from "openai";
// const openai = new OpenAI({
//     apiKey: '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', dangerouslyAllowBrowser: true
// });

// export async function sendMsgToOpenAI(message) {
//     const res = await openai.chat.completions.create({
//         messages: [{ role: "user", content: message }],
//         model: "gpt-3.5-turbo",
//     });
//     return res.data.choices[0].text;
// }


import Anthropic from '@anthropic-ai/sdk';

const anthropic = new Anthropic({
  // apiKey: "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", // defaults to process.env["ANTHROPIC_API_KEY"]
});

export async function sendMsgToOpenAI(message: string) {
  const msg = await anthropic.messages.create({
    model: "claude-3-opus-20240229",
    max_tokens: 1024,
    messages: [{ role: "user", content: message }],
  });
  return msg;
}