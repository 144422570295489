import React, { useContext, useState, useEffect } from "react";
import "./signup.scss";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { AuthContext } from "../../context/AuthContext";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
  getDocs,
  query,
  increment,
  where
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Stepper from "../../components/stepper/stepper";
import Sidebar from 'react-sidebar';
import { getAuth } from "firebase/auth";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgress } from '@mui/material';
import Select from 'react-select';

const PersonalInfoStep = ({ name, setName, email, setEmail, surname, setSurname, password, setPassword, email1, setEmail1, password1, setPassword1, inputType1, inputType2, toggleToPassword, toggleToText, toggleVisibility1, toggleVisibility2 }) => (
  <>
    <input
      type="text"
      placeholder="First Name"
      onChange={(e) => setName(e.target.value)}
      value={name}
    />
    <div className="password-container">
      <input
        type="text"
        placeholder="Surname"
        onChange={(e) => setSurname(e.target.value)}
        value={surname}
      />
      {/* <span className="input-header">Optional</span> */}
    </div>
    <input
      type="email"
      placeholder="Enter your email"
      onChange={(e) => setEmail(e.target.value)}
      value={email}
    />
    <input
      type="email"
      placeholder="Confirm your email"
      onChange={(e) => setEmail1(e.target.value)}
      value={email1}
    />
    <div className="password-container">
      <input
        type={inputType1}
        placeholder="Create a password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        // onClick={toggleToText}
        // onBlur={toggleToPassword} 
      />
      <span className="password-toggle" onClick={toggleVisibility1}>
          {inputType1 === 'password' ? <img src="/images/eye.png" alt="eye" className="eye-logo" /> : <img src="/images/hide.png" alt="eye" className="eye-logo" />}
        </span>
      {/* <span className="input-header">Must be at least 8 characters.</span> */}
    </div>
    <div className="password-container">
      <input
        type={inputType2}
        placeholder="Confirm your password"
        onChange={(e) => setPassword1(e.target.value)}
        value={password1}
        // onClick={toggleToText}
        // onBlur={toggleToPassword} 
      />
      <span className="password-toggle2" onClick={toggleVisibility2}>
          {inputType2 === 'password' ? <img src="/images/eye.png" alt="eye" className="eye-logo" /> : <img src="/images/hide.png" alt="eye" className="eye-logo" />}
        </span>
      {/* <span className="input-header">Must be at least 8 characters.</span> */}
    </div>
  </>
);

const BusinessInfoStep = ({ bname, setBName, email, setEmail, nbname, setNBName, link, setLink, referral, setReferral, imagesSelected, handleFileChange }) => {
  const generateReferralCode = () => {
    // Generate a random alphanumeric referral code
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleGenerateReferral = () => {
    const newReferralCode = generateReferralCode();
    setReferral(newReferralCode);
  };

  return (
    <>
      <input
        type="text"
        placeholder="Business Name"
        onChange={(e) => setBName(e.target.value)}
        value={bname}
      />
      <input
        type="email"
        placeholder="Enter your email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        readOnly
      />
      <div className="password-container">
        <input
          type="text"
          placeholder="Nature of Business"
          onChange={(e) => setNBName(e.target.value)}
          value={nbname}
        />
      </div>
      <div className="password-container">
        <input
          type="text"
          placeholder="Business link"
          onChange={(e) => setLink(e.target.value)}
          value={link}
        />
      </div>
      <div className="password-container" style={{ marginTop: 10 }}>
        <input
          type="text"
          placeholder="Referral Code? (Optional)"
          onChange={(e) => setReferral(e.target.value)}
          value={referral}
        />
        {/* <span className="input-header" onClick={handleGenerateReferral} style={{color: '#2E54FF'}}>Generate referral code</span> */}
      </div>
      <div className="image-container">
        <div className="icon-container">
          <DriveFolderUploadOutlinedIcon className="icon" />
          <label htmlFor="file">Add Logo Image</label>
        </div>
        <input
          type="file"
          id="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
      {imagesSelected && (<span>Image selected successfully!</span>)}
    </>
  );
};

const AddressInfoStep = ({ 
  address1, setAddress1, 
  contact1, setContact1, 
  address2, setAddress2, 
  contact2, setContact2, 
  country, setCountry, 
  inputValue, handleInputChange, 
  filteredCountries, countriesList,
  zipCode, setZipCode,
  region, setRegion 
}) => {

  // Define state variable to track if region has been fetched
const [regionFetched, setRegionFetched] = useState(false);

  useEffect(() => {
    // Function to fetch geolocation data based on region
    const fetchGeolocation = async () => {
      try {
        // Use Google Geocoding API
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(region)}&key=AIzaSyAUlvtxGpDfZvmYpvt02uwn8EunzwgSUk8`);
        // console.log(response)
        if (response.ok) {
          const data = await response.json();
          // console.log(data); // Log the parsed data to inspect it
          if (data.status === 'OK' && data.results.length > 0) {
            const countryComponent = data.results[0].address_components.find(component => component.types.includes('country'));
            if (countryComponent) {
              // Extract country short name or long name based on your preference
              // const countryShortName = countryComponent.short_name;
              const countryLongName = countryComponent.long_name;
              // console.log(countryLongName)
              setCountry(countryLongName);

              // Check if the country has changed
              if (country !== countryLongName) {
                const selectedCountryData = countriesList.find(item => item.name === countryLongName);
                
                if (selectedCountryData) {
                  const phoneCode = selectedCountryData.phoneCode;
                  setContact1(`${phoneCode}`);
                  setContact2(`${phoneCode}`);
                } 

              }

            }
          } else {
            // console.error('No results found for the given region');
          }
          // Set region fetched to true after successful fetch
          setRegionFetched(true);
        } else {
          console.error('Failed to fetch geolocation data');
        }
      } catch (error) {
        console.error('Error fetching geolocation data:', error);
      }
    };

    // Call the fetchGeolocation function when region changes
    if (region) {
      fetchGeolocation();
    } else {
      // If no region is selected, set the country and contact numbers to empty
      setCountry('');
      setContact1('');
      setContact2('');
    }
  }, [region, setCountry, setContact1, setContact2, countriesList]);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
  
    // Find the selected country's data
    const selectedCountryData = countriesList.find(item => item.name === selectedCountry);
    
    // If the selected country's data is found, update the contact numbers
    if (selectedCountryData) {
      const phoneCode = selectedCountryData.phoneCode;
      setContact1(`${phoneCode}`);
      setContact2(`${phoneCode}`);
    }
  };  

  const handleContact1Change = (e) => {
    const input = e.target.value;
    const countryCode = `+${countriesList.find(item => item.name === country)?.phoneCode}`;
    if (input.startsWith(countryCode)) {
      setContact1(input);
    } else if (input === "+" || input === countryCode) {
      setContact1(countryCode);
    } else if (input.startsWith("+")) {
      setContact1(input);
    } else {
      setContact1(countryCode + input);
    }
  };

  const handleContact2Change = (e) => {
    const input = e.target.value;
    const countryCode = `+${countriesList.find(item => item.name === country)?.phoneCode}`;
    if (input.startsWith(countryCode)) {
      setContact2(input);
    } else if (input === "+" || input === countryCode) {
      setContact2(countryCode);
    } else if (input.startsWith("+")) {
      setContact2(input);
    } else {
      setContact2(countryCode + input);
    }
  };

  const handleNumericInput = (e) => {
    const key = e.key;
  
    // Allow only numeric characters and certain control keys
    if (!/^[0-9]$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder="Business Address 1"
        onChange={(e) => setAddress1(e.target.value)}
        value={address1}
      />
      <div className="password-container">
        <input
          type="text"
          placeholder="Business Address 2"
          onChange={(e) => setAddress2(e.target.value)}
          value={address2}
        />
      </div>
      {/* <input
        list="countries" // Attach datalist to input
        onChange={handleCountryChange} // Handle input change
        placeholder="Search Country"
        value={country} // Set value of input field
      /> */}

      {/* Use datalist instead of select */}
      {/* <datalist id="countries"> */}
        {/* Populate datalist options */}
        {/* {filteredCountries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </datalist> */}
      <input
          type="text"
          placeholder="Region"
          onChange={(e) => setRegion(e.target.value)}
          value={region}
        />
      {/* <input
        list="countries" // Attach datalist to input
        onChange={handleInputChange} // Handle input change
        placeholder="Search Country"
      /> */}
      {region && regionFetched && (
      <select 
        id="countries"
        value={region && regionFetched ? country : ''} // Set value to country when condition is met, otherwise set to empty string
        onChange={handleCountryChange} // Update country and phone code
      >
        <option value="">Select country</option>
        {filteredCountries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
      )}
      {/* Conditionally render ZIP code input */}
      {region && country && countriesList.find(item => item.name === country)?.zipCode && (
        <input
          type="text"
          placeholder={`Postal/ZIP Code (${countriesList.find(item => item.name === country).zipCode})`}
          onChange={(e) => setZipCode(e.target.value)}
          value={zipCode}
        />
      )}
      {region && country && countriesList.find(item => item.name === country)?.phoneCode && (
      <input
        type="tel" // Change type to 'tel' for phone numbers
        placeholder="contact 1"
        onChange={handleContact1Change}
        value={contact1}
        inputMode="numeric"
        pattern="[0-9]*"
        onKeyDown={handleNumericInput}
      />
      )}
      {region && country && countriesList.find(item => item.name === country)?.phoneCode && (
      <div className="password-container">
        <input
          type="tel"
          placeholder="contact 2 (Optional)"
          onChange={handleContact2Change}
          value={contact2}
          inputMode="numeric"
          pattern="[0-9]*"
          onKeyDown={handleNumericInput}
        />
      </div>
      )}
    </>
  );
};

const PaymentInfo= ({ currentStep, handleStripeRedirect1, handleStripeRedirect2, gbpCountries, convertEuroToDollar, userCountry }) => (
  <>
  {/* <div className="payment-options">
    <div className="best-value">
      <p className="best-text">Best value</p>
    </div>
    <div className="row-payment">
    <div className="payment-text">
    <p className="year-text">12 months</p>
    <div className="money-row">
    <p className="price">£ 20</p><p className="month-text"> /annually</p>
    </div>
    <p className="terms-text1"><span className="custom-color">£ 20</span> every<span className="custom-color"> 12</span> months</p>
    <p className="terms-text2">VAT and local taxes may apply</p>
    {userCountry && !gbpCountries.includes(userCountry) && (
    <p className="terms-text2 custom-color1">£ 20 ≈ ${convertEuroToDollar(20)} (USD)</p>
    )}
  </div>
  <button className="subscribe-button" onClick={handleStripeRedirect1}>
      <p className="subscribe-text">Subscribe now</p>
    </button>
  </div>
  </div> */}
  {/* <div className="payment-options1">
  <div className="row-payment">
    <div className="payment-text">
    <p className="year-text">1 month</p>
    <div className="money-row">
    <p className="price">£ 1.99</p><p className="month-text"> /monthly</p>
    </div>
    <p className="terms-text1"><span className="custom-color">£ 1.99</span> every month</p>
    <p className="terms-text2">VAT and local taxes may apply</p>
    {userCountry && !gbpCountries.includes(userCountry) && (
    <p className="terms-text2 custom-color1">£ 1.99 ≈ ${convertEuroToDollar(2)} (USD)</p>
    )}
  </div>
  <button className="subscribe-button" onClick={handleStripeRedirect2}>
    <p className="subscribe-text">Subscribe now</p>
    </button>
  </div>
    </div> */}
  </>
);

const SignUp = () => {
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [bname, setBName] = useState("");
  const [nbname, setNBName] = useState("");
  const [link, setLink] = useState("");
  const [referral, setReferral] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [number, setNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [contact1, setContact1] = useState("");
  const [contact2, setContact2] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [subscription, setSubscription] = useState("");
  const [files, setFiles] = useState([]);
  const [data, setData] = useState({ images: [] });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [percentages, setPercentages] = useState([]);
  const [imagesSelected, setImagesSelected] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepsCompleted, setStepsCompleted] = useState({
    personalInfo: false,
    businessInfo: false,
    addressInfo: false,
    paymentInfo: false,
  });
  const [payment, setPayment] = useState("no");

  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [inputType1, setInputType1] = useState('password');
  const [inputType2, setInputType2] = useState('password');

  const toggleVisibility1 = () => {
    setInputType1((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const toggleVisibility2 = () => {
    setInputType2((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  // Function to toggle input type based on screen size
  // const toggleToPassword = () => {
  //   setInputType('password');
  // };

  // const toggleToText = () => {
  //   setInputType('text');
  // };

  // Fetch the list of countries when the component mounts
  useEffect(() => {
    fetchCountries();
  }, []);

// All countries
// length 252
const countriesList = [
  {name: "Afghanistan", code: "AF", phoneCode: "+93", zipCode: "1001"},
  {name: "Aland Islands", code: "AX", phoneCode: "+358", zipCode: "22100"},
  {name: "Albania", code: "AL", phoneCode: "+355", zipCode: "1001"},
  {name: "Algeria", code: "DZ", phoneCode: "+213", zipCode: "16000"},
  {name: "American Samoa", code: "AS", phoneCode: "+1-684", zipCode: "96799"},
  {name: "Andorra", code: "AD", phoneCode: "+376", zipCode: "AD100"},
  {name: "Angola", code: "AO", phoneCode: "+244", zipCode: ""},
  {name: "Anguilla", code: "AI", phoneCode: "+1-264", zipCode: ""},
  {name: "Antarctica", code: "AQ", phoneCode: "+672", zipCode: ""},
  {name: "Antigua and Barbuda", code: "AG", phoneCode: "+1-268", zipCode: ""},
  {name: "Argentina", code: "AR", phoneCode: "+54", zipCode: "C1002AAA"},
  {name: "Armenia", code: "AM", phoneCode: "+374", zipCode: "375000"},
  {name: "Aruba", code: "AW", phoneCode: "+297", zipCode: ""},
  {name: "Australia", code: "AU", phoneCode: "+61", zipCode: "2000"},
  {name: "Austria", code: "AT", phoneCode: "+43", zipCode: "1010"},
  {name: "Azerbaijan", code: "AZ", phoneCode: "+994", zipCode: "AZ1000"},
  {name: "Bahamas", code: "BS", phoneCode: "+1-242", zipCode: ""},
  {name: "Bahrain", code: "BH", phoneCode: "+973", zipCode: ""},
  {name: "Bangladesh", code: "BD", phoneCode: "+880", zipCode: "1207"},
  {name: "Barbados", code: "BB", phoneCode: "+1-246", zipCode: ""},
  {name: "Belarus", code: "BY", phoneCode: "+375", zipCode: "220000"},
  {name: "Belgium", code: "BE", phoneCode: "+32", zipCode: "1000"},
  {name: "Belize", code: "BZ", phoneCode: "+501", zipCode: ""},
  {name: "Benin", code: "BJ", phoneCode: "+229", zipCode: ""},
  {name: "Bermuda", code: "BM", phoneCode: "+1-441", zipCode: ""},
  {name: "Bhutan", code: "BT", phoneCode: "+975", zipCode: ""},
  {name: "Bolivia", code: "BO", phoneCode: "+591", zipCode: ""},
  {name: "Bonaire, Sint Eustatius and Saba", code: "BQ", phoneCode: "+599", zipCode: ""},
  {name: "Bosnia and Herzegovina", code: "BA", phoneCode: "+387", zipCode: "71000"},
  {name: "Botswana", code: "BW", phoneCode: "+267", zipCode: ""},
  {name: "Bouvet Island", code: "BV", phoneCode: "+47", zipCode: ""},
  {name: "Brazil", code: "BR", phoneCode: "+55", zipCode: "70000-000"},
  {name: "British Indian Ocean Territory", code: "IO", phoneCode: "+246", zipCode: ""},
  {name: "Brunei Darussalam", code: "BN", phoneCode: "+673", zipCode: "BB3513"},
  {name: "Bulgaria", code: "BG", phoneCode: "+359", zipCode: "1000"},
  {name: "Burkina Faso", code: "BF", phoneCode: "+226", zipCode: ""},
  {name: "Burundi", code: "BI", phoneCode: "+257", zipCode: ""},
  {name: "Cambodia", code: "KH", phoneCode: "+855", zipCode: ""},
  {name: "Cameroon", code: "CM", phoneCode: "+237", zipCode: ""},
  {name: "Canada", code: "CA", phoneCode: "+1", zipCode: "A1A 1A1"},
  {name: "Cape Verde", code: "CV", phoneCode: "+238", zipCode: ""},
  {name: "Cayman Islands", code: "KY", phoneCode: "+1-345", zipCode: ""},
  {name: "Central African Republic", code: "CF", phoneCode: "+236", zipCode: ""},
  {name: "Chad", code: "TD", phoneCode: "+235", zipCode: ""},
  {name: "Chile", code: "CL", phoneCode: "+56", zipCode: "8320000"},
  {name: "China", code: "CN", phoneCode: "+86", zipCode: "100000"},
  {name: "Christmas Island", code: "CX", phoneCode: "+61", zipCode: ""},
  {name: "Cocos (Keeling) Islands", code: "CC", phoneCode: "+61", zipCode: ""},
  {name: "Colombia", code: "CO", phoneCode: "+57", zipCode: "110221"},
  {name: "Comoros", code: "KM", phoneCode: "+269", zipCode: ""},
  {name: "Congo", code: "CG", phoneCode: "+242", zipCode: ""},
  {name: "Congo, Democratic Republic of the Congo", code: "CD", phoneCode: "+243", zipCode: ""},
  {name: "Cook Islands", code: "CK", phoneCode: "+682", zipCode: ""},
  {name: "Costa Rica", code: "CR", phoneCode: "+506", zipCode: "10101"},
  {name: "Cote D'Ivoire", code: "CI", phoneCode: "+225", zipCode: ""},
  {name: "Croatia", code: "HR", phoneCode: "+385", zipCode: "10000"},
  {name: "Cuba", code: "CU", phoneCode: "+53", zipCode: "10100"},
  {name: "Curacao", code: "CW", phoneCode: "+599", zipCode: ""},
  {name: "Cyprus", code: "CY", phoneCode: "+357", zipCode: "6023"},
  {name: "Czech Republic", code: "CZ", phoneCode: "+420", zipCode: "100 00"},
  {name: "Denmark", code: "DK", phoneCode: "+45", zipCode: "1000"},
  {name: "Djibouti", code: "DJ", phoneCode: "+253", zipCode: ""},
  {name: "Dominica", code: "DM", phoneCode: "+1-767", zipCode: ""},
  {name: "Dominican Republic", code: "DO", phoneCode: "+1-809, +1-829, +1-849", zipCode: ""},
  {name: "Ecuador", code: "EC", phoneCode: "+593", zipCode: "090150"},
  {name: "Egypt", code: "EG", phoneCode: "+20", zipCode: "11511"},
  {name: "El Salvador", code: "SV", phoneCode: "+503", zipCode: "CP 1101"},
  {name: "Equatorial Guinea", code: "GQ", phoneCode: "+240", zipCode: ""},
  {name: "Eritrea", code: "ER", phoneCode: "+291", zipCode: ""},
  {name: "Estonia", code: "EE", phoneCode: "+372", zipCode: "10141"},
  {name: "Ethiopia", code: "ET", phoneCode: "+251", zipCode: "1000"},
  {name: "Falkland Islands (Malvinas)", code: "FK", phoneCode: "+500", zipCode: "FIQQ 1ZZ"},
  {name: "Faroe Islands", code: "FO", phoneCode: "+298", zipCode: ""},
  {name: "Fiji", code: "FJ", phoneCode: "+679", zipCode: ""},
  {name: "Finland", code: "FI", phoneCode: "+358", zipCode: "00100"},
  {name: "France", code: "FR", phoneCode: "+33", zipCode: "75001"},
  {name: "French Guiana", code: "GF", phoneCode: "+594", zipCode: "97300"},
  {name: "French Polynesia", code: "PF", phoneCode: "+689", zipCode: "987"},
  {name: "French Southern Territories", code: "TF", phoneCode: "+262", zipCode: ""},
  {name: "Gabon", code: "GA", phoneCode: "+241", zipCode: ""},
  {name: "Gambia", code: "GM", phoneCode: "+220", zipCode: ""},
  {name: "Georgia", code: "GE", phoneCode: "+995", zipCode: ""},
  {name: "Germany", code: "DE", phoneCode: "+49", zipCode: "10115"},
  {name: "Ghana", code: "GH", phoneCode: "+233", zipCode: ""},
  {name: "Gibraltar", code: "GI", phoneCode: "+350", zipCode: ""},
  {name: "Greece", code: "GR", phoneCode: "+30", zipCode: "105 57"},
  {name: "Greenland", code: "GL", phoneCode: "+299", zipCode: ""},
  {name: "Grenada", code: "GD", phoneCode: "+1-473", zipCode: ""},
  {name: "Guadeloupe", code: "GP", phoneCode: "+590", zipCode: "97100"},
  {name: "Guam", code: "GU", phoneCode: "+1-671", zipCode: ""},
  {name: "Guatemala", code: "GT", phoneCode: "+502", zipCode: "01001"},
  {name: "Guernsey", code: "GG", phoneCode: "+44-1481", zipCode: ""},
  {name: "Guinea", code: "GN", phoneCode: "+224", zipCode: ""},
  {name: "Guinea-Bissau", code: "GW", phoneCode: "+245", zipCode: ""},
  {name: "Guyana", code: "GY", phoneCode: "+592", zipCode: ""},
  {name: "Haiti", code: "HT", phoneCode: "+509", zipCode: ""},
  {name: "Heard Island and McDonald Islands", code: "HM", phoneCode: "", zipCode: ""},
  {name: "Holy See (Vatican City State)", code: "VA", phoneCode: "+379", zipCode: "00120"},
  {name: "Honduras", code: "HN", phoneCode: "+504", zipCode: "11101"},
  {name: "Hong Kong", code: "HK", phoneCode: "+852", zipCode: ""},
  {name: "Hungary", code: "HU", phoneCode: "+36", zipCode: "1011"},
  {name: "Iceland", code: "IS", phoneCode: "+354", zipCode: ""},
  {name: "India", code: "IN", phoneCode: "+91", zipCode: "110001"},
  {name: "Indonesia", code: "ID", phoneCode: "+62", zipCode: "40115"},
  {name: "Iran, Islamic Republic of Persian Gulf", code: "IR", phoneCode: "+98", zipCode: "14118-14614"},
  {name: "Iraq", code: "IQ", phoneCode: "+964", zipCode: ""},
  {name: "Ireland", code: "IE", phoneCode: "+353", zipCode: ""},
  {name: "Isle of Man", code: "IM", phoneCode: "+44-1624", zipCode: ""},
  {name: "Israel", code: "IL", phoneCode: "+972", zipCode: "9614301"},
  {name: "Italy", code: "IT", phoneCode: "+39", zipCode: "00100"},
  {name: "Jamaica", code: "JM", phoneCode: "+1-876", zipCode: ""},
  {name: "Japan", code: "JP", phoneCode: "+81", zipCode: "100-0001"},
  {name: "Jersey", code: "JE", phoneCode: "+44-1534", zipCode: ""},
  {name: "Jordan", code: "JO", phoneCode: "+962", zipCode: "11118"},
  {name: "Kazakhstan", code: "KZ", phoneCode: "+7", zipCode: "010000"},
  {name: "Kenya", code: "KE", phoneCode: "+254", zipCode: "00100"},
  {name: "Kiribati", code: "KI", phoneCode: "+686", zipCode: ""},
  {name: "Korea, Democratic People's Republic of Korea", code: "KP", phoneCode: "+850", zipCode: ""},
  {name: "Korea, Republic of South Korea", code: "KR", phoneCode: "+82", zipCode: "03048"},
  {name: "Kosovo", code: "XK", phoneCode: "+383", zipCode: ""},
  {name: "Kuwait", code: "KW", phoneCode: "+965", zipCode: ""},
  {name: "Kyrgyzstan", code: "KG", phoneCode: "+996", zipCode: ""},
  {name: "Laos", code: "LA", phoneCode: "+856", zipCode: ""},
  {name: "Latvia", code: "LV", phoneCode: "+371", zipCode: "LV-1009"},
  {name: "Lebanon", code: "LB", phoneCode: "+961", zipCode: "2030 3054"},
  {name: "Lesotho", code: "LS", phoneCode: "+266", zipCode: ""},
  {name: "Liberia", code: "LR", phoneCode: "+231", zipCode: ""},
  {name: "Libyan Arab Jamahiriya", code: "LY", phoneCode: "+218", zipCode: ""},
  {name: "Liechtenstein", code: "LI", phoneCode: "+423", zipCode: "9496"},
  {name: "Lithuania", code: "LT", phoneCode: "+370", zipCode: "01137"},
  {name: "Luxembourg", code: "LU", phoneCode: "+352", zipCode: "1111"},
  {name: "Macao", code: "MO", phoneCode: "+853", zipCode: ""},
  {name: "Macedonia", code: "MK", phoneCode: "+389", zipCode: "1000"},
  {name: "Madagascar", code: "MG", phoneCode: "+261", zipCode: ""},
  {name: "Malawi", code: "MW", phoneCode: "+265", zipCode: ""},
  {name: "Malaysia", code: "MY", phoneCode: "+60", zipCode: "50450"},
  {name: "Maldives", code: "MV", phoneCode: "+960", zipCode: ""},
  {name: "Mali", code: "ML", phoneCode: "+223", zipCode: ""},
  {name: "Malta", code: "MT", phoneCode: "+356", zipCode: "NXR 9030"},
  {name: "Marshall Islands", code: "MH", phoneCode: "+692", zipCode: ""},
  {name: "Martinique", code: "MQ", phoneCode: "+596", zipCode: "97200"},
  {name: "Mauritania", code: "MR", phoneCode: "+222", zipCode: ""},
  {name: "Mauritius", code: "MU", phoneCode: "+230", zipCode: ""},
  {name: "Mayotte", code: "YT", phoneCode: "+262", zipCode: "97600"},
  {name: "Mexico", code: "MX", phoneCode: "+52", zipCode: "01000"},
  {name: "Micronesia, Federated States of Micronesia", code: "FM", phoneCode: "+691", zipCode: ""},
  {name: "Moldova", code: "MD", phoneCode: "+373", zipCode: "MD-2001"},
  {name: "Monaco", code: "MC", phoneCode: "+377", zipCode: "98000"},
  {name: "Mongolia", code: "MN", phoneCode: "+976", zipCode: "210136"},
  {name: "Montenegro", code: "ME", phoneCode: "+382", zipCode: "81000"},
  {name: "Montserrat", code: "MS", phoneCode: "+1-664", zipCode: ""},
  {name: "Morocco", code: "MA", phoneCode: "+212", zipCode: ""},
  {name: "Mozambique", code: "MZ", phoneCode: "+258", zipCode: ""},
  {name: "Myanmar", code: "MM", phoneCode: "+95", zipCode: ""},
  {name: "Namibia", code: "NA", phoneCode: "+264", zipCode: ""},
  {name: "Nauru", code: "NR", phoneCode: "+674", zipCode: ""},
  {name: "Nepal", code: "NP", phoneCode: "+977", zipCode: ""},
  {name: "Netherlands", code: "NL", phoneCode: "+31", zipCode: "1012 JS"},
  {name: "Netherlands Antilles", code: "AN", phoneCode: "+599", zipCode: ""},
  {name: "New Caledonia", code: "NC", phoneCode: "+687", zipCode: "98800"},
  {name: "New Zealand", code: "NZ", phoneCode: "+64", zipCode: "0110"},
  {name: "Nicaragua", code: "NI", phoneCode: "+505", zipCode: ""},
  {name: "Niger", code: "NE", phoneCode: "+227", zipCode: ""},
  {name: "Nigeria", code: "NG", phoneCode: "+234", zipCode: "900001"},
  {name: "Niue", code: "NU", phoneCode: "+683", zipCode: ""},
  {name: "Norfolk Island", code: "NF", phoneCode: "+672", zipCode: "2899"},
  {name: "Northern Mariana Islands", code: "MP", phoneCode: "+1-670", zipCode: ""},
  {name: "Norway", code: "NO", phoneCode: "+47", zipCode: "0101"},
  {name: "Oman", code: "OM", phoneCode: "+968", zipCode: "100"},
  {name: "Pakistan", code: "PK", phoneCode: "+92", zipCode: "44000"},
  {name: "Palau", code: "PW", phoneCode: "+680", zipCode: ""},
  {name: "Palestinian Territory, Occupied", code: "PS", phoneCode: "+970", zipCode: ""},
  {name: "Panama", code: "PA", phoneCode: "+507", zipCode: ""},
  {name: "Papua New Guinea", code: "PG", phoneCode: "+675", zipCode: ""},
  {name: "Paraguay", code: "PY", phoneCode: "+595", zipCode: "1850"},
  {name: "Peru", code: "PE", phoneCode: "+51", zipCode: "15046"},
  {name: "Philippines", code: "PH", phoneCode: "+63", zipCode: "1000"},
  {name: "Pitcairn", code: "PN", phoneCode: "+64", zipCode: ""},
  {name: "Poland", code: "PL", phoneCode: "+48", zipCode: "00-001"},
  {name: "Portugal", code: "PT", phoneCode: "+351", zipCode: "1000-001"},
  {name: "Puerto Rico", code: "PR", phoneCode: "+1-787, +1-939", zipCode: ""},
  {name: "Qatar", code: "QA", phoneCode: "+974", zipCode: ""},
  {name: "Reunion", code: "RE", phoneCode: "+262", zipCode: "97400"},
  {name: "Romania", code: "RO", phoneCode: "+40", zipCode: "030167"},
  {name: "Russia", code: "RU", phoneCode: "+7", zipCode: "101000"},
  {name: "Rwanda", code: "RW", phoneCode: "+250", zipCode: ""},
  {name: "Saint Barthelemy", code: "BL", phoneCode: "+590", zipCode: "97133"},
  {name: "Saint Helena, Ascension and Tristan Da Cunha", code: "SH", phoneCode: "+290", zipCode: "STHL 1ZZ"},
  {name: "Saint Kitts and Nevis", code: "KN", phoneCode: "+1-869", zipCode: ""},
  {name: "Saint Lucia", code: "LC", phoneCode: "+1-758", zipCode: ""},
  {name: "Saint Martin", code: "MF", phoneCode: "+590", zipCode: "97150"},
  {name: "Saint Pierre and Miquelon", code: "PM", phoneCode: "+508", zipCode: "97500"},
  {name: "Saint Vincent and the Grenadines", code: "VC", phoneCode: "+1-784", zipCode: ""},
  {name: "Samoa", code: "WS", phoneCode: "+685", zipCode: ""},
  {name: "San Marino", code: "SM", phoneCode: "+378", zipCode: "47890"},
  {name: "Sao Tome and Principe", code: "ST", phoneCode: "+239", zipCode: ""},
  {name: "Saudi Arabia", code: "SA", phoneCode: "+966", zipCode: ""},
  {name: "Senegal", code: "SN", phoneCode: "+221", zipCode: ""},
  {name: "Serbia", code: "RS", phoneCode: "+381", zipCode: "11000"},
  {name: "Seychelles", code: "SC", phoneCode: "+248", zipCode: ""},
  {name: "Sierra Leone", code: "SL", phoneCode: "+232", zipCode: ""},
  {name: "Singapore", code: "SG", phoneCode: "+65", zipCode: "099705"},
  {name: "Sint Maarten", code: "SX", phoneCode: "+1-721", zipCode: ""},
  {name: "Slovakia", code: "SK", phoneCode: "+421", zipCode: "811 01"},
  {name: "Slovenia", code: "SI", phoneCode: "+386", zipCode: "1000"},
  {name: "Solomon Islands", code: "SB", phoneCode: "+677", zipCode: ""},
  {name: "Somalia", code: "SO", phoneCode: "+252", zipCode: ""},
  {name: "South Africa", code: "ZA", phoneCode: "+27", zipCode: "9301"},
  {name: "South Sudan", code: "SS", phoneCode: "+211", zipCode: ""},
  {name: "South Georgia and the South Sandwich Islands", code: "GS", phoneCode: "", zipCode: ""},
  {name: "Spain", code: "ES", phoneCode: "+34", zipCode: "28001"},
  {name: "Sri Lanka", code: "LK", phoneCode: "+94", zipCode: "20000"},
  {name: "Sudan", code: "SD", phoneCode: "+249", zipCode: ""},
  {name: "Suriname", code: "SR", phoneCode: "+597", zipCode: ""},
  {name: "Svalbard and Jan Mayen", code: "SJ", phoneCode: "+47", zipCode: ""},
  {name: "Swaziland", code: "SZ", phoneCode: "+268", zipCode: ""},
  {name: "Sweden", code: "SE", phoneCode: "+46", zipCode: "111 20"},
  {name: "Switzerland", code: "CH", phoneCode: "+41", zipCode: "3000"},
  {name: "Syrian Arab Republic", code: "SY", phoneCode: "+963", zipCode: ""},
  {name: "Taiwan", code: "TW", phoneCode: "+886", zipCode: "100"},
  {name: "Tajikistan", code: "TJ", phoneCode: "+992", zipCode: ""},
  {name: "Tanzania, United Republic of Tanzania", code: "TZ", phoneCode: "+255", zipCode: ""},
  {name: "Thailand", code: "TH", phoneCode: "+66", zipCode: "10100"},
  {name: "Timor-Leste", code: "TL", phoneCode: "+670", zipCode: ""},
  {name: "Togo", code: "TG", phoneCode: "+228", zipCode: ""},
  {name: "Tokelau", code: "TK", phoneCode: "+690", zipCode: ""},
  {name: "Tonga", code: "TO", phoneCode: "+676", zipCode: ""},
  {name: "Trinidad and Tobago", code: "TT", phoneCode: "+1-868", zipCode: ""},
  {name: "Tunisia", code: "TN", phoneCode: "+216", zipCode: "1002"},
  {name: "Turkey", code: "TR", phoneCode: "+90", zipCode: "06050"},
  {name: "Turkmenistan", code: "TM", phoneCode: "+993", zipCode: ""},
  {name: "Turks and Caicos Islands", code: "TC", phoneCode: "+1-649", zipCode: ""},
  {name: "Tuvalu", code: "TV", phoneCode: "+688", zipCode: ""},
  {name: "Uganda", code: "UG", phoneCode: "+256", zipCode: ""},
  {name: "Ukraine", code: "UA", phoneCode: "+380", zipCode: "01001"},
  {name: "United Arab Emirates", code: "AE", phoneCode: "+971", zipCode: ""},
  {name: "United Kingdom", code: "GB", phoneCode: "+44", zipCode: "EC1A 1BB"},
  {name: "United States", code: "US", phoneCode: "+1", zipCode: "10001"},
  {name: "Uruguay", code: "UY", phoneCode: "+598", zipCode: "11100"},
  {name: "Uzbekistan", code: "UZ", phoneCode: "+998", zipCode: ""},
  {name: "Vanuatu", code: "VU", phoneCode: "+678", zipCode: ""},
  {name: "Venezuela, Bolivarian Republic of Venezuela", code: "VE", phoneCode: "+58", zipCode: "1010"},
  {name: "Vietnam", code: "VN", phoneCode: "+84", zipCode: "700000"},
  {name: "Virgin Islands, British", code: "VG", phoneCode: "+1-284", zipCode: ""},
  {name: "Virgin Islands, U.S.", code: "VI", phoneCode: "+1-340", zipCode: ""},
  {name: "Wallis and Futuna", code: "WF", phoneCode: "+681", zipCode: "98600"},
  {name: "Western Sahara", code: "EH", phoneCode: "+212", zipCode: ""},
  {name: "Yemen", code: "YE", phoneCode: "+967", zipCode: ""},
  {name: "Zambia", code: "ZM", phoneCode: "+260", zipCode: ""},
  {name: "Zimbabwe", code: "ZW", phoneCode: "+263", zipCode: ""}
];

// console.log(countriesList);

  // Function to fetch list of countries
  const fetchCountries = async () => {
    try {
      const countryNames = countriesList.map(country => country.name);
      // console.log(countryNames)
      setCountries(countryNames);
      setFilteredCountries(countryNames); // Initialize filtered countries with all countries
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountryByCoordinates(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  // const fetchCountry = () => {
  //   fetch("http://ip-api.com/json/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('Fetched country data:', data); // Log the fetched country data
  //       const countryName = data.country || "";
  //       setUserCountry(countryName);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting user's country:", error);
  //     });
  // };  

  const fetchCountryByCoordinates = (latitude, longitude) => {
    // You can use reverse geocoding API here to fetch country based on latitude and longitude
    // Example: Fetch country using Google Maps Geocoding API
    const apiKey = 'AIzaSyAUlvtxGpDfZvmYpvt02uwn8EunzwgSUk8';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Extract country from the response
        const country = data.results[0].address_components.find(
          (component) => component.types.includes("country")
        );
        const countryName = country ? country.long_name : "";
        // console.log(countryName)
        setUserCountry(countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country by coordinates:", error);
      });
  };

  const [euroToDollarRate, setEuroToDollarRate] = useState(null);

  useEffect(() => {
    // Fetch exchange rate from euros to dollars
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch("https://api.exchangerate-api.com/v4/latest/GBP");
        const data = await response.json();
        const exchangeRate = data.rates.USD;
        setEuroToDollarRate(exchangeRate);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  // Function to convert euros to dollars
  const convertEuroToDollar = (amount) => {
    if (euroToDollarRate) {
      return (amount * euroToDollarRate).toFixed(2);
    } else {
      return amount; // Return original amount if exchange rate is not available yet
    }
  };

  const gbpCountries = [
    "United Kingdom", 
    "Isle of Man",
    "Channel Islands",
    "Gibraltar",
    "Falkland Islands",
    "Saint Helena, Ascension, and Tristan da Cunha",
    "British Antarctic Territory",
    "South Georgia and the South Sandwich Islands"
  ];

  const checkAndUpdateReferral = async (referralCode) => {
    try {
      if (!referralCode) {
        console.log('Referral code is empty.');
        return; // Exit function if referral code is empty
      }
  
      // Query to check if referral code exists in the main data
      const mainDataQuery = query(collection(db, 'users'), where('referral', '==', referralCode));
      const mainDataSnapshot = await getDocs(mainDataQuery);
  
      // Check if referral code exists in the main data
      if (!mainDataSnapshot.empty) {
        // Referral code exists in the main data, update the referral count
        mainDataSnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { referralCount: increment(1) });
          console.log('Referral code found in main data. Referral count incremented.');
        });
        return; // Exit function after updating main data
      }
  
      // Referral code doesn't exist in the main data, check additional data
      const usersCollectionRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollectionRef);
  
      // Convert snapshot to array
      const usersDataArray = usersSnapshot.docs.map(doc => doc.data());
  
      // Check if referral code was found in additional data
      const referralFoundInAdditionalData = usersDataArray.some(userData => {
        return Array.isArray(userData.additionalData) && userData.additionalData.some(data => data.referral === referralCode);
      });
  
      // If referral code was found in additional data, update referral count
      if (referralFoundInAdditionalData) {
        usersSnapshot.forEach(async (userDoc) => {
          const userData = userDoc.data();
          if (Array.isArray(userData.additionalData)) {
            const updatedAdditionalData = userData.additionalData.map(data => {
              if (data.referral === referralCode) {
                return { ...data, referralCount: (data.referralCount || 0) + 1 };
              }
              return data;
            });
  
            // Update the document with the updated additional data
            await updateDoc(userDoc.ref, { additionalData: updatedAdditionalData });
            console.log('Referral code found in additional data. Referral count incremented.');
          }
        });
      } else {
        console.log('Referral code not found.');
      }
    } catch (error) {
      console.error('Error checking and updating referral code:', error);
    }
  };
   

  const handleCheckReferral = () => {
    checkAndUpdateReferral(referral);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setInputValue(inputValue);
  
    let matchedCountry = '';
  
    // Check for specific cases where constituent countries should map to a larger country
    if (inputValue === 'england' || inputValue === 'scotland' || inputValue === 'wales' || inputValue === 'northern ireland') {
      matchedCountry = 'united kingdom';
    } else if (inputValue === 'america') {
      matchedCountry = 'united states';
    } else {
      matchedCountry = inputValue;
    }
  
    // Filter countries based on matched country or input value
    const filtered = countries.filter(country =>
      country.toLowerCase().startsWith(matchedCountry) || country.toLowerCase().startsWith(inputValue)
    );
    setFilteredCountries(filtered);
  };  

  const handleHome = (e) => {
    navigate("/start");
  };

  const handleLogin = (e) => {
    navigate("/login");
  };

  const handleTerms = () => {
    navigate("/terms");
  };

  const handleAboutUs = () => {
    navigate("/about");
  };

  const handleHelp = () => {
    navigate("/help");
  };

  const auth = getAuth();

  const { currentUser } = useContext(AuthContext);

  const currentUid = currentUser?.uid;

  useEffect(() => {
    const storedFormData = sessionStorage.getItem('formData');
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      setName(parsedFormData.name);
      setEmail(parsedFormData.email);
      setSurname(parsedFormData.surname);
      setPassword(parsedFormData.password);
      setBName(parsedFormData.bname);
      setNBName(parsedFormData.nbname);
      setLink(parsedFormData.link);
      setReferral(parsedFormData.referral);
      setAddress1(parsedFormData.address1);
      setContact1(parsedFormData.contact1);
      setAddress2(parsedFormData.address2);
      setContact2(parsedFormData.contact2);
      setCountry(parsedFormData.country);
      setIsChecked(parsedFormData.isChecked)
      setIsChecked(parsedFormData.zipCode)
      setIsChecked(parsedFormData.region)
    }
  }, []);

  const handleStripeRedirect1 = () => {
    sessionStorage.setItem('formData', JSON.stringify({ name, surname, email, password, bname, nbname, link, referral, address1, contact1, address2, contact2, country, isChecked, zipCode, region }));
    setPayment("yes"); // Update payment state variable
    // Redirect to the specified link when the button is clicked
    window.location.href = 'https://buy.stripe.com/4gw3eE2tT5Z2bgA4gg';
    setCurrentStep(4); // Update the current step after redirection
  };
  
  const handleStripeRedirect2 = () => {
    sessionStorage.setItem('formData', JSON.stringify({ name, surname, email, password, bname, nbname, link, referral, address1, contact1, address2, contact2, country, isChecked, zipCode, region }));
    setPayment("yes"); // Update payment state variable
    // Redirect to the specified link when the button is clicked
    window.location.href = 'https://buy.stripe.com/3cs2aAfgF0EI98s8wx';
    setCurrentStep(4); // Update the current step after redirection
  };

  const location = useLocation();

const searchParams = new URLSearchParams(location.search);
const urlId = searchParams.get("id");

// State to determine whether to skip the first step
const [skipFirstStep, setSkipFirstStep] = useState(false);

// Effect to check if the uid in the URL matches the current user's uid
useEffect(() => {
  // Function to fetch user data from Firestore based on uid
  const fetchUserData = async (uid) => {
    try {
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // User exists, so skip the first step
        setSkipFirstStep(true);
      } else {
        // User doesn't exist, render the first step
        setSkipFirstStep(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Check if uid in URL matches the current user's uid
  if (urlId && currentUid && urlId === currentUid) {
    // If they match, fetch user data
    fetchUserData(currentUid);
  } else {
    // If they don't match, render the first step
    setSkipFirstStep(false);
  }
}, [urlId, currentUid]);

  useEffect(() => {
    // Parse URL parameters to get the step
    const searchParams = new URLSearchParams(location.search);
    const stepParam = searchParams.get('step');
    
    // Update the current step based on the URL parameter
    if (stepParam && !isNaN(stepParam)) {
      const targetStep = parseInt(stepParam, 10);
      setCurrentStep(targetStep);
    }
  }, [location.search]);

  useEffect(() => {
    // Parse URL parameters to get the session_id and step
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const stepParam = searchParams.get('step');
  
    // Check if session_id is present
    if (sessionId) {

      const stripe = require('stripe')('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');

      stripe.checkout.sessions.retrieve(sessionId, function(err, session) {
        if (err) {
          console.error('Error retrieving session:', err);
        } else {
          // console.log('Session retrieved:', session);
      
          // Access the subscription ID associated with the session
          const subscriptionId = session.subscription;
          setSubscription(subscriptionId);
          // Set payment state variable to "yes" when the session_id is present
          setPayment("yes");
          // Perform actions for a successful payment
          alert('Payment was successful!');
          // You can redirect or perform other actions here
          }
        });

    } else {
      // Set payment state variable to "no" when the session_id is not present
      setPayment("no");
    }
  
    // Update the current step based on the URL parameter
    if (stepParam && !isNaN(stepParam)) {
      const targetStep = parseInt(stepParam, 10);
      setCurrentStep(targetStep);
    }
  }, [location.search]);  

  useEffect(() => {
    const fetchEmailFromDatabase = async (id) => {
      try {
        const docRef = doc(db, "users", id); // Assuming your collection is named "users"
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const userData = docSnap.data();
          const userEmail = userData.email;
          setEmail(userEmail);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching email from database:", error);
      }
    };
  
    // Parse URL parameters to get the id
    const searchParams = new URLSearchParams(location.search);
    const idParam = searchParams.get("id");
  
    // Call the function to fetch the email if the id parameter exists
    if (idParam) {
      fetchEmailFromDatabase(idParam);
    }
  }, [location.search]);
  
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const [isSigningUp, setIsSigningUp] = useState(false);

// Inside handleSignUp function
const handleSignUp = async (e) => {

  setIsSigningUp(true); // Set the flag to indicate signup process is in progress
  
  if (e) {
    e.preventDefault();
  }

  setIsLoading(true);

  handleCheckReferral();

  try {
    // Step 1: Upload the image (retrieve it from local storage)
    const selectedImage = localStorage.getItem('selectedImage');
    if (selectedImage) {
      // Convert the base64 image string back to a Blob object
      const byteCharacters = atob(selectedImage.split(',')[1]);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }
      const imageBlob = new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' });

      // Upload the image to Firebase Storage
      const imageName = new Date().getTime() + 'image.jpg';
      const storageRef = ref(storage, imageName);
      const uploadTask = uploadBytesResumable(storageRef, imageBlob);
      
      await uploadTask;
      
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

      // Step 2: Check if the user already exists with the provided email
      const querySnapshot = await getDocs(collection(db, "users"));
      const existingUser = querySnapshot.docs.find(doc => doc.data().email === email);

      if (existingUser) {
        // User already exists, update the existing document
        const userId = existingUser.id;

        // Get the existing user data
        const userData = existingUser.data();

        // Add the new data to a different array within the existing user document
        const newData = {
          email,
          name,
          surname,
          image: downloadURL,
          bname,
          nbname,
          link,
          referral,
          address1,
          contact1,
          address2,
          contact2,
          region,
          country,
          zipCode,
          payment: payment,
          subscriptionId: subscription,
        };

        // If the user document doesn't have an array field for storing additional data, create one
        if (!userData.additionalData) {
          userData.additionalData = [];
        }

        // Push the new data into the additionalData array
        userData.additionalData.push(newData);

        // Update the user document with the new data
        await updateDoc(doc(db, "users", userId), userData);
      } else {
        // User doesn't exist, create a new document in the original collection
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        const userData = {
          email,
          name,
          surname,
          image: downloadURL,
          bname,
          nbname,
          link,
          referral,
          address1,
          contact1,
          address2,
          contact2,
          region,
          country,
          zipCode,
          payment: payment,
          subscriptionId: subscription,
        };

        // Add the user data to the original collection
        await setDoc(doc(db, "users", user.uid), userData);
      
        // Step 4: Send email verification
        await sendEmailVerification(user);
      }

      // Set the state to indicate images have been selected successfully
      setImagesSelected(true);
      
      // Clear input fields
      setName("");
      setSurname("");
      setBName("");
      setNBName("");
      setLink("");
      setReferral("");
      setEmail("");
      setNumber("");
      setPassword("");
      setAddress1("");
      setAddress2("");
      setContact1("");
      setContact2("");
      setRegion("");
      setCountry("");
      setZipCode("");
      setSubscription("");
      setFiles([]);
      setData({});
      setPercentages([]);
      localStorage.removeItem('selectedImage');
      sessionStorage.removeItem('formData')

      // Show success message and navigate
      const confirmation = window.confirm(
        "You have successfully registered! A verification link has been sent to your email."
      );
      
      // If the user clicks "OK," navigate to the login page
      if (confirmation) {
        navigate("/");
      } else {
        // If the user clicks "Cancel," navigate to the start page
        navigate("/start");
      }      
    }
  } catch (error) {
    setError(true);
    console.error("Error signing up:", error.message);
  }
  finally {
    setIsSigningUp(false); // Reset the flag after signup process completes
    setIsLoading(false); // Reset loading state after sign-up process completes
  }
};

// Inside handleFileChange function
const handleFileChange = (e) => {
  const fileList = e.target.files;
  const fileArray = Array.from(fileList);
  
  if (fileArray.length > 1) {
    alert("You can only upload a maximum of 1 image.");
    return;
  }
  
  const selectedFile = fileArray[0];
  setFiles([selectedFile]);
  setPercentages([0]);
  setImagesSelected(true);

  // Store the selected image in local storage
  const reader = new FileReader();
  reader.onload = function (event) {
    localStorage.setItem('selectedImage', event.target.result);
  };
  reader.readAsDataURL(selectedFile);
};

  // Helper function to get the key for stepsCompleted based on the current step
const getStepKey = (step) => {
  switch (step) {
    case 1:
      return "personalInfo";
    case 2:
      return "businessInfo";
    case 3:
      return "addressInfo";
    case 4:
      return "paymentInfo";
    default:
      return "";
  }
};

useEffect(() => {
  if (payment === "yes") {
    handleSignUp(); // Call handleSignUp when payment is completed
  }
}, [payment]);

const handleNextStep = async (e) => {
  e.preventDefault();

  let isValid = false;

  switch (currentStep) {
    case 1:
      isValid = await validatePersonalInfo(); // Wait for validation to complete
      break;
    case 2:
      isValid = validateBusinessInfo();
      break;
    case 3:
      isValid = validateAddressInfo();
      break;
    case 4:
      isValid = true;
      break;
    default:
      break;
  }

  if (isValid) {
    setStepsCompleted((prev) => ({ ...prev, [getStepKey(currentStep)]: true }));

    if (currentStep < 4) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (sessionId) {
        // Call handleSignUp when the "Finish" button is clicked and session_id is present
        handleSignUp(e);
      } else {
        // Show confirmation dialog before proceeding without payment
        const confirmation = window.confirm('You are registering the account without a payment so the details will not be displayed. Proceed?');
        if (confirmation) {
          handleSignUp(e);
        }
      }
    }
  }
};
  
  // Helper function to validate personal information
  const validatePersonalInfo = async () => {
    if (skipFirstStep) { // Skip validation if id parameter is present
      setFormValid(true);
      return true;
    }
    if (name && surname && email && password && isChecked) {
      if (
        password.length < 8 ||
        !/[A-Z]/.test(password) ||
        !/[a-z]/.test(password) ||
        !/\d/.test(password) ||
        !/[^A-Za-z0-9]/.test(password)
      ) {
        setFormValid(false);
        alert(
          "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
        );
        return false;
      }
      if (email !== email1) {
        setFormValid(false);
        alert("Please ensure that the same email is entered in both fields.");
        return false;
      }
      if (password !== password1) {
        setFormValid(false);
        alert("Passwords do not match. Please make sure they are identical.");
        return false;
      }
  
      // Check if email already exists
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        setFormValid(false);
        alert("This email is already registered. Please use a different email.");
        return false; // Return false to halt the form submission
      }
  
      // If everything is valid, set formValid to true
      setFormValid(true);
      return true;
    } else {
      setFormValid(false);
      alert("Please fill in all required fields before proceeding.");
      return false;
    }
  };
  
  const checkEmailExists = async (email) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const users = querySnapshot.docs.map(doc => doc.data());
      return users.some(user => user.email === email);
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  
  
  // Helper function to validate business information
  const validateBusinessInfo = () => {
    if (bname && email && nbname && link && imagesSelected && isChecked) {
      setFormValid(true);
      return true;
    } else {
      setFormValid(false);
      alert(
        "Please fill in all required fields and select an image before proceeding."
      );
      return false;
    }
  };
  
  // Helper function to validate address information
  const validateAddressInfo = () => {
    const countryCodeInContact1 = countriesList.some(country => contact1 === country.phoneCode);
    const isZipCodeValid = !countriesList.find(item => item.name === country)?.zipCode || zipCode;
  
    if (address1 && contact1 && address2 && country && !countryCodeInContact1 && isChecked && isZipCodeValid && region) {
      setFormValid(true);
      return true;
    } else {
      setFormValid(false);
      if (countryCodeInContact1) {
        alert("Please enter phone number with country code. Please fill in all required fields before proceeding.");
      } else if (!isZipCodeValid) {
        alert("Please enter a valid Postal/ZIP code. Please fill in all required fields before proceeding.");
      } else {
        alert("Please fill in all required fields before proceeding.");
      }
      return false;
    }
  };   

   // Dynamic header based on the current step
   const getHeaderForStep = (step) => {
    switch (step) {
      case 1:
        return "Become an Exclusive Member";
      case 2:
        return "Business Information";
      case 3:
        return "Address Information";
      // Add more cases if needed for additional steps
      default:
        return "Step " + step;
    }
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleChatbot = () => {
    navigate("/chatbot");
    window.scrollTo(0, 0)
  };

  const sidebarContent = (
    <div className="sidebar">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>
        <button type="submit" className="home-text" onClick={handleChatbot}>Chat bot</button>    
        <button type="submit" className="button5" onClick={handleLogin}>Login</button>
        <button type="submit" className="button6">Signup</button>
        <button type="submit" className="home-text" onClick={handleAboutUs}>About Us</button>
        <button type="submit" className="home-text" onClick={handleHelp}>Help</button>
    </div>
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    // Allow checking if it's unchecked, prevent unchecking if it's already checked
    if (!isChecked) {
      setIsChecked(true);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Decrease the step if it's greater than 1
    }
  };  

  const sessionId = searchParams.get('session_id');

  return (
    <div className="container-signup">
      <div className="header">
       <div className="logo-img-container" onClick={handleHome} style={{ zIndex: '1', cursor: 'pointer' }}>
       <img src="/images/brand-logo.png" alt="logo" className="logo1"/>
        <img src="/images/logo.png" alt="logo" className="logo2"/>
        <div className="logo-name">BrandForgeX</div>
        </div>
        <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="white"/>
            </svg>
            </button>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
      </div>
      {currentStep === 4 && (
      <div className="payment-image-container">
        <div className="payment-image-text">Our vision is to <span className="highlighted">revolutionize</span> the way <br/> brands and  advertisers target, reach</div>
      <img src="/images/cube-signup.svg" alt="logo" className="payment-image"/>
      </div>
      )}
      <div className="container2">
      <div className="login">
      <div className="text-container">
          {currentStep !== 4 && (
            <>
              <div className="text1">{getHeaderForStep(currentStep)}</div>
              <div className="text2">Please enter your details in the fields below.</div>
            </>
          )}
        </div>
        <form id="signupForm" onSubmit={handleSignUp} className={currentStep === 4 ? 'payment-section' : ''}>
          {!skipFirstStep && currentStep === 1 && (
            <PersonalInfoStep
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              email1={email1}
              setEmail1={setEmail1}
              surname={surname}
              setSurname={setSurname}
              password={password}
              setPassword={setPassword}
              password1={password1}
              setPassword1={setPassword1}
              inputType1={inputType1}
              inputType2={inputType2}
              toggleVisibility1={toggleVisibility1}
              toggleVisibility2={toggleVisibility2}
            />
          )}
          {currentStep === 2 && (
            <BusinessInfoStep
              bname={bname}
              setBName={setBName}
              email={email}
              setEmail={setEmail}
              nbname={nbname}
              setNBName={setNBName}
              link={link}
              setLink={setLink}
              referral={referral}
              setReferral={setReferral}
              imagesSelected={imagesSelected}
              handleFileChange={handleFileChange}
            />
          )}
          {currentStep === 3 && (
            <AddressInfoStep
              address1={address1}
              setAddress1={setAddress1}
              address2={address2}
              setAddress2={setAddress2}
              contact1={contact1}
              setContact1={setContact1}
              contact2={contact2}
              setContact2={setContact2}
              country={country}
              setCountry={setCountry}
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              filteredCountries={filteredCountries}
              countriesList={countriesList}
              zipCode={zipCode}
              setZipCode={setZipCode}
              region={region}
              setRegion={setRegion}
            />
          )}
          {currentStep === 4 && (
              <PaymentInfo currentStep={currentStep} handleStripeRedirect1={handleStripeRedirect1} handleStripeRedirect2={handleStripeRedirect2} gbpCountries={gbpCountries} convertEuroToDollar={convertEuroToDollar} userCountry={userCountry} />
            )}
        </form>
        <div className="text3"><div><input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}  style={{marginRight: '5px', zIndex: '1', height: '15px', width: '15px'}}/>By Creating an Account, it means you agree to our </div><span className="underline" onClick={handleTerms}> Privacy Policy </span> and <span className="underline" onClick={handleTerms}>Terms of Service</span></div>
        <div className="button-container1">
        {(!sessionId && currentStep > 1 && !isSigningUp) && (
            <button
              type="button"
              className="back-button1"
              onClick={handlePreviousStep}
            >
              Back
            </button>
          )}
        <button
          type="submit"
          className="button2"
          disabled={isButtonDisabled || isLoading}
          onClick={handleNextStep}
          form="signupForm"
        >
          {isLoading ? (
            <CircularProgress
            size={24}
            color="inherit"
            style={{ marginRight: 8 }} // Adjust spacing if needed
          />        
          ) : (
            currentStep === 4 ? "Finish" : "Next"
          )}
        </button>
        {(!sessionId && currentStep > 1 && !isSigningUp) && (
            <button
              type="button"
              className="back-button2"
              onClick={handlePreviousStep}
            >
              Back
            </button>
          )}
        </div>
          <Stepper currentStep={currentStep} className="stepper"/>
        <div className="text4" onClick={handleLogin}>Already have an account? <span className="login-link">Log in</span></div>
      </div>
      </div>
    </div>
  );
};

export default SignUp;
