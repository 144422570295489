import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getDocs, collection, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";

const List = () => {
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser?.uid;
  const [orderDetails, setOrderDetails] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchOrderDetails = async () => {
      try {
        if (uid) {
          const ordersQuery = query(
            collection(db, "orders"),
            where("storeId", "==", uid)
          );

          const ordersSnapshot = await getDocs(ordersQuery);

          if (isMounted && !ordersSnapshot.empty) {
            const ordersData = ordersSnapshot.docs.map((orderDoc) => ({
              id: orderDoc.id,
              ...orderDoc.data()
            }));
            setOrderDetails(ordersData);
          } else {
            console.log("No orders found for the user");
          }
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();

    return () => {
      isMounted = false;
    };
  }, [uid]);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 500 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Item ID</TableCell>
            <TableCell className="tableCell">Product</TableCell>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">Order Date</TableCell>
            <TableCell className="tableCell">Price</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderDetails.slice(0, 5).map((order) => (
            <TableRow key={order?.id}>
              <TableCell className="tableCell">{order?.id.slice(0, 5)}...</TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  {order.cartItems
                    .map((item) => item?.name)
                    .join(', ')}
                </div>
              </TableCell>
              <TableCell className="tableCell">{order.user?.name}</TableCell>
              <TableCell className="tableCell">
                {order.timestamp && format(order.timestamp.toDate(), "MMMM d, yyyy 'at' h:mm a")}
              </TableCell>
              <TableCell className="tableCell">${order.user?.totalAmount}</TableCell>
              <TableCell className="tableCell">{order.paymentMethod}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${order.status}`}>{order.status}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;