import "./referal.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const Referal = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate()
  const { productId } = useParams();

  const auth = getAuth();

  const { currentUser } = useContext(AuthContext);

  const uid = currentUser?.uid

  const [userDetails, setUserDetails] = useState(null);
  const [userImage, setUserImage] = useState(null);

  const fetchUserDetails = async () => {
    try {
      if (uid) {
        let userData;
  
        // Check if the uid is the special one
        if (uid === "QuYLksMLriOhMtUcYo7TGCNrRUh1") {
          userData = {
            name: "Groce Admin",
            email: "groce@admin",
            referralCode: "", // You can set a default value for the referral code here
          };
        } else {
          // Fetch details from the database
          const userDoc = await getDoc(doc(db, "users", uid));
          if (userDoc.exists) {
            userData = userDoc.data();
          } else {
            console.log("User not found in the database");
            return;
          }
        }
  
        // Extract referral code from userData
        const referralCode = userData.referral || ""; // Ensure referral code is not null or undefined
  
        // Update the state with user details and referral code
        setUserDetails(userData);
        setReferral(referralCode);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };  
  
  // Call the function when the component mounts or when the currentUser changes
  useEffect(() => {
    fetchUserDetails();
  }, [uid]);  

  useEffect(() => {
    const uploadFile = async () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, name);
    
      // Use a unique name for the storage reference
      const uploadTask = uploadBytesResumable(storageRef, file);
    
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    
          // Check if the images array has already reached the maximum allowed (3 images)
          if (data?.images?.length >= 3) {
            // Ask the user for confirmation to replace one image
            const confirmReplace = window.confirm(
              "You already have 3 images. Do you want to replace one?"
            );
    
            if (confirmReplace) {
              // Replace the first image in the array with the new one
              setData((prev) => ({
                ...prev,
                images: [downloadURL, ...(prev.images || []).slice(1)],
              }));
            }
          } else {
            // Add the new image to the images array
            setData((prev) => ({
              ...prev,
              images: [...(prev.images || []), downloadURL],
            }));
          }
        }
      );
    };    
  
    file && uploadFile();
  }, [file]);  

  console.log(data);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
  
    // Check if the input field is the email field, and ignore the change
    if (id === "email") {
      return;
    }
  
    setData({ ...data, [id]: value });
  };  

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // Assuming "data" contains the properties you want to update in the document
      const storeDocRef = doc(db, "stores", uid); // Use the UID of the current user
  
      // Additional checks or operations before updating the document can be added here
  
      await updateDoc(storeDocRef, {
        ...data,
        timeStamp: serverTimestamp(),
      });
  
      // Assuming "navigate" is a function to navigate, like from react-router
      navigate(-1);
    } catch (err) {
      console.error("Error updating store details:", err);
    }
  };  

  const handleDeleteImage = (index) => {
    const fileInput = document.getElementById("file");
  
    // Trigger a click event on the file input
    fileInput.click();
  };  

  const handleSelectImage = (e) => {
    const selectedFile = e.target.files[0];
  
    // Log information for debugging
    console.log("Selected File:", selectedFile);
  
    // Read the selected image as a data URL and update the state
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log("Reader Result:", reader.result);
      setUserImage(reader.result);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    } else {
      // If no file is selected, clear the preview
      setUserImage(null);
    }
  };  

  const handleResetPassword = async () => {
    try {
      // Trigger the password reset email
      await sendPasswordResetEmail(auth, currentUser.email);
      alert("Password reset email sent. Please check your email.");
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      alert("Failed to send password reset email. Please try again later.");
    }
  };

  const [invitationLink, setInvitationLink] = useState("https://brandforgex.com");

  const handleCopy = () => {
    // Create a temporary input element to facilitate copying to clipboard
    const tempInput = document.createElement("input");
    tempInput.value = invitationLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // You can provide user feedback here, such as an alert or tooltip
    alert("Copied to clipboard!");
  };

  const openInNewWindow = (url) => {
    window.open(url, "_blank");
  };

  const handleTwitterClick = () => {
    // Optionally, you can also copy the text
    handleCopy();

    // Open Twitter in a new window
    openInNewWindow("https://twitter.com/");
  };

  const handleFacebookClick = () => {
    // Optionally, you can also copy the text
    handleCopy();

    // Open Facebook in a new window
    openInNewWindow("https://facebook.com/");
  };

  const handleLinkedInClick = () => {
    // Optionally, you can also copy the text
    handleCopy();

    // Open LinkedIn in a new window
    openInNewWindow("https://linkedin.com/");
  };

  const handleGmailClick = () => {
    // Optionally, you can also copy the text
    handleCopy();

    // Open Gmail in a new window
    openInNewWindow("mailto:?subject=BrandForgeX&body=Check out BrandForgeX: https://brandforgex.com");
  };

  const [referral, setReferral] = useState("");

  const generateReferralCode = () => {
    // Generate a random alphanumeric referral code
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleGenerateReferral = async () => {
    try {
      const newReferralCode = generateReferralCode();
  
      // Update the referral code in Firestore
      const storeDocRef = doc(db, "users", uid); // Use the UID of the current user
      await updateDoc(storeDocRef, {
        referral: newReferralCode,
      });
  
      // Update the state with the generated referral code
      setReferral(newReferralCode);
    } catch (error) {
      console.error("Error generating referral code:", error);
      // Handle the error accordingly, e.g., show an error message to the user
    }
  };  

  return (
    <div className="Referal">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">Tell Business Owners about BrandForgeX <br/> and both get free credits!</div>
        <div className="card-container">
          <div className="referalCard">
          <div className="cardHead">Copy and Share your invitation link</div>
          <div className="copy-container">
          <input
          className="copy"
          value={invitationLink}
          readOnly
          />
          <button className="copy-button" onClick={handleCopy}>Copy</button>
          </div>
          <div className="copy-container1">
          <input
            className="copy"
            value={referral}
            readOnly // Set readOnly to true if referral code exists
            onChange={(e) => setReferral(e.target.value)}
            placeholder="Generate your referral code here"
          />
          {referral ? (
            <button className="copy-button" onClick={handleCopy}>Copy</button>
          ) : (
            <button className="copy-button1" onClick={handleGenerateReferral}>Generate</button>
          )}
        </div>
          <div className="share-container">
            <div className="share-text">share to</div>
            <a className="share-icon" href="#" onClick={handleTwitterClick}>
            <img src="/images/x.svg" alt="X" className="outer-circle"/>
            </a>
            <a className="share-icon" href="#" onClick={handleFacebookClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
              <circle cx="17.5" cy="17.5" r="17.5" fill="#D9D9D9"/>
              <path d="M18 8C12.5 8 8 12.49 8 18.02C8 23.02 11.66 27.17 16.44 27.92V20.92H13.9V18.02H16.44V15.81C16.44 13.3 17.93 11.92 20.22 11.92C21.31 11.92 22.45 12.11 22.45 12.11V14.58H21.19C19.95 14.58 19.56 15.35 19.56 16.14V18.02H22.34L21.89 20.92H19.56V27.92C21.9164 27.5478 24.0622 26.3455 25.6099 24.5301C27.1576 22.7146 28.0054 20.4056 28 18.02C28 12.49 23.5 8 18 8Z" fill="#433F3F"/>
            </svg>
            </a>
            <a className="share-icon" href="#" onClick={handleLinkedInClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
              <circle cx="17.5" cy="17.5" r="17.5" fill="#D9D9D9"/>
              <path d="M25 8C25.5304 8 26.0391 8.21071 26.4142 8.58579C26.7893 8.96086 27 9.46957 27 10V24C27 24.5304 26.7893 25.0391 26.4142 25.4142C26.0391 25.7893 25.5304 26 25 26H11C10.4696 26 9.96086 25.7893 9.58579 25.4142C9.21071 25.0391 9 24.5304 9 24V10C9 9.46957 9.21071 8.96086 9.58579 8.58579C9.96086 8.21071 10.4696 8 11 8H25ZM24.5 23.5V18.2C24.5 17.3354 24.1565 16.5062 23.5452 15.8948C22.9338 15.2835 22.1046 14.94 21.24 14.94C20.39 14.94 19.4 15.46 18.92 16.24V15.13H16.13V23.5H18.92V18.57C18.92 17.8 19.54 17.17 20.31 17.17C20.6813 17.17 21.0374 17.3175 21.2999 17.5801C21.5625 17.8426 21.71 18.1987 21.71 18.57V23.5H24.5ZM12.88 13.56C13.3256 13.56 13.7529 13.383 14.0679 13.0679C14.383 12.7529 14.56 12.3256 14.56 11.88C14.56 10.95 13.81 10.19 12.88 10.19C12.4318 10.19 12.0019 10.3681 11.685 10.685C11.3681 11.0019 11.19 11.4318 11.19 11.88C11.19 12.81 11.95 13.56 12.88 13.56ZM14.27 23.5V15.13H11.5V23.5H14.27Z" fill="#433F3F"/>
            </svg>
            </a>
            <a className="share-icon" href="#" onClick={handleGmailClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
              <circle cx="17.5" cy="17.5" r="17.5" fill="#D9D9D9"/>
              <path d="M26 14L18 19L10 14V12L18 17L26 12M26 10H10C8.89 10 8 10.89 8 12V24C8 24.5304 8.21071 25.0391 8.58579 25.4142C8.96086 25.7893 9.46957 26 10 26H26C26.5304 26 27.0391 25.7893 27.4142 25.4142C27.7893 25.0391 28 24.5304 28 24V12C28 10.89 27.1 10 26 10Z" fill="#433F3F"/>
            </svg>
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referal;
