import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import ListProducts from "../../components/table-products/Table-products";

const SingleProduct = () => {
  const { productId } = useParams();
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (productId) {
          // If productId doesn't contain an underscore, just fetch the user document directly
          if (!productId.includes("_")) {
            const userDoc = await getDoc(doc(db, "users", productId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              // Set the item data from the user document
              setItemData({
                id: productId,
                ...userData
              });
            } else {
              console.log("User not found");
            }
          } else {
            // If productId contains an underscore, split it to get userId and index
            const [userId, index] = productId.split("_");
            if (userId && index) {
              const userDoc = await getDoc(doc(db, "users", userId));
              if (userDoc.exists()) {
                const userData = userDoc.data();
                // Check if additional data exists and index is within range
                if (
                  userData.additionalData &&
                  userData.additionalData[index]
                ) {
                  // Set the item data from the additional array
                  setItemData({
                    id: userId,
                    ...userData.additionalData[index]
                  });
                } else {
                  console.log("Additional data not found");
                }
              } else {
                console.log("User not found");
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserData();
  }, [productId]);  

  useEffect(() => {
    console.log("UserData:", itemData);
  }, [itemData]);

  const openLinkInNewWindow = (link) => {
    // Check if the link starts with https://, if not prepend https://
    const url = link.startsWith('https://') ? link : `https://${link}`;
    window.open(url, "_blank");
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {/* <Link  to={`/products/update/${productId}`} style={{ textDecoration: "none" }}>
            <div className="editButton">Edit</div>
            </Link> */}
            <h1 className="title">Information</h1>
            <div className="item">
              {itemData?.image ? (
                <img src={itemData?.image} alt="" className="itemImg" />
              ) : (
                <p>No image available</p>
              )}
              <div className="details">
                <h1 className="itemTitle">{itemData?.bname}</h1>
                <div className="detailItem">
                  <span className="itemKey">User Name:</span>
                  <span className="itemValue">{itemData?.name} {itemData?.surname}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{itemData?.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address</span>
                  <span className="itemValue">{itemData?.address1}, {itemData?.address2}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{itemData?.country}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Category:</span>
                  <span className="itemValue">{itemData?.nbname}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Payment:</span>
                  <span className="itemValue">{itemData?.payment}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Contact:</span>
                  <span className="itemValue">{itemData?.contact1}, {itemData?.contact2}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Link:</span>
                  <a href="#" className="itemValue" onClick={() => openLinkInNewWindow(itemData?.link)}>
                      {itemData?.link}
                  </a>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Referral:</span>
                  <span className="itemValue">{itemData?.referral}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
        {/* <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <ListProducts/>
        </div> */}
      </div>
    </div>
  );
};

export default SingleProduct;